import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import AuthenticateStatus from 'components/authenticate/authenticate_status';
import { AuthenticateStore } from '@seedlang/stores';
import { AppUI, TreeUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import { isBlank, isPresent, getCookieValue, setCookieValue } from '@seedlang/utils';
import Alert from 'components/alert';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';
import Text from 'components/text';
import styled from '@emotion/styled';
import Checkbox from 'components/checkbox';
import Spinner from 'components/spinner';

const PrivacyLink = styled.a`
  font-size: 12px;
  text-decoration: underline;
  width: 100%;
  text-align: center;
  display: block;
  border-top: 1px solid #d9dadc;
  padding-top: 10px;
  margin-top: 10px;
  color: black;
`;

const InputWrapper = styled.span`
  label {
    color: ${props => props.color};
  }
`;

const Terms = styled.div`
  margin: 10px 0 5px 0;
  text-align: ${props => props.textAlign};
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  align-items: start;
  font-size: 14px;
  line-height: 1.5;
`;


@observer
class RegistrationForm extends React.Component {
  @observable email = '';
  @observable password = '';
  @observable name = '';
  @observable termsAccepted = false;
  @observable invalidEmail = false;
  @observable emailFocused = false;
  @observable submitting = false;
  @observable userAgreedToEmail = 'unset';
  @observable alreadyExists = false;
  @observable isBrowser = typeof window !== 'undefined' && typeof window.localStorage !== 'undefined';

  @computed get buttonBackground() {
    if (!AppUI.siteIsDefault) {
      return AppUI.site.accentColor;
    }
    return Theme.blue;
  }

  @computed get disabled() {
    return this.submitting || this.invalidEmail || isBlank(this.password) || this.password.length < 6 || isBlank(this.email) || isBlank(this.name) || (!AppUI.siteIsDefault && !this.termsAccepted);
  }

  @computed get showPaymentTerms() {
    return AppUI.routeStore.routeName === 'memberships.create';
  }

  @autobind validateEmail(email) {
    if (email === 'test--' || email === 'test++') { return true }
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  @autobind onChangeEmail(email) {
    this.invalidEmail = !this.validateEmail(email);
    this.email = email;
  }

  @autobind onSubmit(e) {
    let groupId = window.location.href.split("/group/")[1];
    e.preventDefault();
    if (this.disabled) { return; }
    this.submitting = true;
    const params = {
      data: {
        email: this.email,
        name: this.name,
        password: this.password,
        password_confirmation: this.password,
        level_id: this.props.levelId || AppUI.onboardingLevelId,
        language_id: this.props.languageId || AppUI.onboardingLanguageId,
        target_language_id: this.props.targetLanguageId,
        promotion_code: getCookieValue('promotion-code'),
        allow_cookies: getCookieValue('allow-cookies'),
        allow_youtube: getCookieValue('allow-youtube'),
        user_agreed_to_email: this.userAgreedToEmail,
      },
      queryStrings: {
        group_id: groupId,
      }
    };
    if (AppUI.user?.id && AppUI.user.guest) {
      AuthenticateStore.convertGuest(params, this.afterRegister);
    } else {
      AuthenticateStore.register(params, this.afterRegister);
    }
  }

  @autobind afterRegister(resp) {
    this.submitting = false;
    if (resp['alreadyExists']) {
      this.alreadyExists = true;
    } else if (resp['unconfirmed']) {
      this.props.onConfirmationRequired({
        email: this.email,
        action: 'registration',
      });
    } else {
      if (AppUI.SIMULATE_MOBILE_AUTH) {
        if (this.isBrowser) {
          localStorage.setItem('jwtToken', resp['jwtToken']);
        }
      }
      AppUI.setAuthUserStoreShowData(resp);
      AppUI.set('registrationSubmitted', true);
      if (AppUI.siteIsDefault) {
        AppUI.loadReviewDecks();
        TreeUI.loadTreesFromTargetLanguage(AppUI.targetLanguageId);
      }
      if (this.props.onCloseModal) {
        this.props.onCloseModal();
      }
      if (this.props.afterRegistration) {
        this.props.afterRegistration();
      }
    }
  }

  @autobind onClickEmailConfirmation() {
    if (this.userAgreedToEmail !== 'true') {
      this.userAgreedToEmail = 'true';
    } else {
      this.userAgreedToEmail = 'unset';
    }
  }

  render() {
    return (
      <span>
        {
          this.submitting &&
            <Spinner
              background={AppUI.siteIsDefault ? Theme.blue : AppUI.site.accentColor}
            />
        }
        {
          !this.submitting && !this.props.hideTitle &&
            <Text
              heading="3"
              center
              margin="0 0 20px 0"
              color="#333"
            >
              Register With Email
            </Text>
        }
        <form acceptCharset="UTF-8" onSubmit={this.onSubmit} id="new_user" method="post" autoComplete="off">
          {
            <AuthenticateStatus
              hideSpinner={this.props.hideSpinner}
            />
          }
          {
            <InputWrapper
              color={this.props.color || "#333"}
            >
              <div className='form-email'>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  autoComplete="off"
                  noValidate
                  value={this.name}
                  onChange={(el) => this.name = el.target.value}
                  id="name" />
              </div>
              <div className='form-email'>
                <label htmlFor="email">Email</label>
                <input
                  onFocus={() => this.emailFocused = true}
                  onBlur={() => this.emailFocused = false}
                  autoComplete="off"
                  noValidate
                  value={this.email}
                  onChange={(el) => this.onChangeEmail(el.target.value)}
                  type="email"
                  id="email" />
                {
                  isPresent(this.email) && this.invalidEmail && !this.emailFocused &&
                    <Alert>
                      This email address is invalid. Please try another.
                    </Alert>
                }
                {
                  this.alreadyExists &&
                    <Alert>
                      This email address already exists.
                    </Alert>
                }
              </div>
              <div className='form-password'>
                <label htmlFor="user_password">Password</label>
                <input
                  noValidate
                  autoComplete="off"
                  id="user_password"
                  title="6 characters minimum"
                  pattern=".{6,}"
                  type="password"
                  value={this.password}
                  onChange={(el) => this.password = el.target.value} />
              </div>
            </InputWrapper>
          }
          {this.renderCheckboxes()}

          <Button
            margin="10px 0 0 0"
            onClick={this.onSubmit}
            disabled={this.disabled}
            height="50px"
            width="100%"
            borderRadius="50px"
            background={this.buttonBackground}
            color="#FFF"
          >
            {this.registrationButtonLabel ?? "Register With Email"}
          </Button>

        </form>

        {!this.props.hidePrivacy &&
          <PrivacyLink
            href='/privacy'
          >
            View Our Privacy Page
          </PrivacyLink>}
      </span>
    );
  }

  renderCheckboxes() {

    const seedlangTermsUrl = "https://www.seedlang.com/terms"
    const seedlangPrivacyUrl = "https://www.seedlang.com/privacy"

    if (AppUI.siteIsDefault) {
      return (
        <Terms textAlign="center">
          <Checkbox
            value={this.termsAccepted}
            onClick={() => this.termsAccepted = !this.termsAccepted }
              selectedBackground={Theme.blue}
          />
          <span>
            <span>I agree to </span>
            <span> the <a className='underline' href={seedlangTermsUrl} target='_blank'>Terms</a> & <a className='underline' href={seedlangPrivacyUrl} target='_blank'>Privacy Policy</a></span>
          </span>
        </Terms>
      )
    }
    else {
      return (
        <>
          <Terms>
            <Checkbox
              value={this.termsAccepted}
              onClick={() => this.termsAccepted = !this.termsAccepted}
              selectedBackground={AppUI.site.accentColor}
            />
            <span>
              <span>I agree to </span>
              <span dangerouslySetInnerHTML={{ __html: AppUI.site.whiteLabelTermsString }} />
              <span> the <a className='underline' href={seedlangTermsUrl} target='_blank'>Terms</a> & <a className='underline' href={seedlangPrivacyUrl} target='_blank'>Privacy Policy</a>{AppUI.siteIsDefault ? '' : ' of the learning platform Seedlang.'}</span>*
            </span>
          </Terms>
          {
            !AppUI.siteIsDefault &&
              <Terms>
                <Checkbox
                  value={this.userAgreedToEmail === 'true'}
                  onClick={this.onClickEmailConfirmation}
                  selectedBackground={AppUI.site.accentColor}
                />
                <span>
                  I want to receive emails from {AppUI.site.teamName} about new content, membership updates, and marketing offers for related products. I can unsubscribe anytime.
                </span>
              </Terms>
          }
        </>
      )
    }
  }
}

export default RegistrationForm;
