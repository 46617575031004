import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Modal from 'components/modal';
import VideoPlayerWithSupplement from 'components/media/video_player_with_supplement';
import ReviewToggle from 'components/review/review_toggle';
import VocabSentence from 'components/vocab/vocab_sentence';
import TranslationComments from 'components/comment/translation_comments';
import { flexCenter } from '@seedlang/style_mixins';
import { AppUI } from '@seedlang/state';

const CenterRow = styled.div`
  margin: ${(props) => props.margin};
  ${flexCenter()}
`;

@observer
class VocabSentencePopup extends React.Component {

  render() {
    return (
      <Modal
        showCloseIcon
        maxWidth="600px"
        height={AppUI.layout.viewportHeight - 80 < 580 ? AppUI.layout.viewportHeight - 80 : 580}
        margin="0 20px"
        width="calc(100% - 40px)"
        iconFill="#000"
        onCloseModal={() => this.props.onClickSentence(null)}
      >
        <VideoPlayerWithSupplement
          hasVideoClip
          autoPlaySupplement
          width={AppUI.layout.isMobile ? (AppUI.layout.viewportWidth - 100) / 2 : 180}
          videoClip={this.props.popupSentence.target.videoClip}
          supplementVideoClip={this.props.popupSentence.videoClip}
        />
        <CenterRow>
          {
            AppUI.userIsLoaded &&
              <ReviewToggle
                interfaceSource="vocab-sentence-popup"
                width="240px"
                buttonText="Add to Reviews"
                buttonHeight="30px"
                buttonBackground="#FFF"
                margin="5px 10px 10px 10px"
                sentence={this.props.popupSentence}
                wordAssociationIds={this.props.popupSentence.wordAssociations.filter(item => item.word.rootWord?.id === this.props.wordId).map(item => item.id)}
              />
          }
        </CenterRow>
        <VocabSentence
          wordId={this.props.wordId}
          sentence={this.props.popupSentence}
          highlightConceptId={this.props.highlightConceptId}
        />
        <TranslationComments
          hideTitle
          padding={0}
          blockTextExpansion
          sentence={this.props.popupSentence}
        />
      </Modal>
    );
  }
}

export default ErrorBoundary(VocabSentencePopup);
