import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { ReviewToggleWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { CheckWithCircleIcon, ThreeDotsIcon, QuestionIcon } from '@seedlang/icons';
import Button from 'components/button/button';
import { AppUI } from '@seedlang/state';
import Modal from 'components/modal';
import ReviewToggleOptions from 'components/review/review_toggle_options';
import Spinner from 'components/spinner';
import { Motion, presets, spring } from 'react-motion';
import { pixify, depixify } from '@seedlang/utils';
import ReviewExplainer from 'components/review/review_explainer';

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  background: ${(props) => props.background};
  border-radius: 30px;
  i {
    margin-right: 5px;
    margin-top: 1px;
    font-size: 14px;
  }
  .check-with-circle-icon svg {
    fill: #FFF;
    width: 20px;
    margin-right: 5px;
  }
`;

const IconWrapper = styled.div`
  width: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  .three-dots-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    width: 4px;
    fill: rgba(255, 255, 255, 0.8);
  }
`;

const HelpButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #A9A9A9;
  position: absolute;
  top: 14;
  color: #FFF;
  font-weight: bold;
  right: -20px;
  font-size: 12px;
  svg {
    fill: #FFF;
  }
`;

@observer
class ReviewToggle extends React.Component {
  @observable showHelpModal = false;

  @computed get buttonWrapperWidth() {
    if (this.props.width) {
      let width = depixify(this.props.width);
      if (this.props.disableToggleButton) {
        width = width - 30;
      }
      return pixify(width);
    }
    return this.props.disableToggleButton ? '240px' : '240px';
  }

  @computed get buttonWidth() {
    let width = depixify(this.buttonWrapperWidth);
    return this.props.disableToggleButton ? pixify(width) : pixify(width - 30);
  }

  static defaultProps = {
    buttonHeight: "38px",
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
        width={this.buttonWrapperWidth}
        style={this.props.style}
      >
        {
          this.showHelpModal &&
            <Modal
              onCloseModal={() => this.showHelpModal = false}
            >
              <ReviewExplainer />
            </Modal>
        }
        {
          this.props.modalVisible &&
            <Modal
              onCloseModal={() => this.props.setModalVisible(false)}
              top="50px"
              width={pixify(AppUI.layout.defaultModalWidth)}
              gradient={AppUI.siteIsDefault}
              background={AppUI.siteIsDefault ? null : AppUI.site.accentColor}
              title={this.props.title}
            >
              <ReviewToggleOptions
                {...this.props}
              />
            </Modal>
        }
        <Motion
          style={{
            scale: spring(this.props.animateScale, this.props.animateScale === 1 ? presets.wobbly : presets.stiff),
          }}
        >
          {
            ({scale}) =>
              <ButtonWrapper
                background={AppUI.siteIsDefault ? "#1699b7" : AppUI.site.accentColor}
                style={{
                  transform: `scale(${scale})`,
                }}
              >
                {
                  this.showSpinner &&
                    <Spinner />
                }
                {
                  !this.showSpinner && !this.props.hasReviews &&
                    <Button
                      width={this.buttonWidth}
                      height="40px"
                      onClick={this.props.onToggleCreateReview}
                    >
                      <i className='fa fa-plus' />
                      {this.props.buttonText}
                    </Button>
                }
                {
                  !this.showSpinner && this.props.hasReviews &&
                    <Button
                      width={this.buttonWidth}
                      height="40px"
                      background={AppUI.siteIsDefault ? "linear-gradient(-134deg, rgba(59, 215, 197, 0.8) 0%, #0DAFF6 100%)" : AppUI.site.accentColor}
                      cursor={this.props.disableToggleButton ? 'default' : 'pointer'}
                      onClick={this.props.onToggleCreateReview}
                    >
                      <CheckWithCircleIcon />
                      Reviewing
                    </Button>
                }
                {
                  !this.props.disableToggleButton &&
                    <IconWrapper
                      onClick={() => this.props.setModalVisible(true)}
                    >
                      <ThreeDotsIcon />
                    </IconWrapper>
                }
              </ButtonWrapper>
          }
        </Motion>
        {
          this.props.showHelpButton &&
            <HelpButton
              onClick={() => this.showHelpModal = true}
            >
              ?
            </HelpButton>
        }
      </Wrapper>
    );
  }
}

export default ReviewToggleWrapper(ReviewToggle);
