import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { ExerciseUI, AppUI } from "@seedlang/state";
import Button from "components/button/button";
import { CrownIcon } from "@seedlang/icons";
import { computed } from "mobx";
import autobind from "autobind-decorator";
import { Link } from "react-router";
import Text from 'components/text';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: ${props => props.margin};
  .fa {
    margin-right: 10px;
  }
`;

const SignInLink = styled.div`
  font-size: 12px;
  color: #333;
  &:hover {
    text-decoration: none;
  }
`;

@observer
class NoAccessButton extends React.Component {

  static defaultProps = {
    margin: "50px 0 30px 0",
    borderRadius: '25px',
    height: "50px",
    fontSize: "16px"
  }

  @computed get showFreeAccountButton() {
    return this.props.register && (!AppUI.userIsSignedIn || AppUI.user.guest);
  }

  @computed get message() {
    if (this.props.message) {
      return this.props.message;
    } if (this.showFreeAccountButton) {
      return "Create Free Account to Try";
    } if (this.props.migrate) {
      return "Switch Payment to our Platform";
    } if (ExerciseUI.user.isMember) {
      return "Upgrade to Unlock";
    } return "Become a Member to Unlock";
  }

  @autobind onClick() {
    if (this.showFreeAccountButton) {
      ExerciseUI.routeStore.routeToNamed('onboarding.register');
    } else if (this.props.migrate) {
      ExerciseUI.routeStore.routeToNamed('settings.billing');
    } else {
      ExerciseUI.routeStore.routeToNamed('memberships.index');
    }
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        <Button
          background={ExerciseUI.site.headerButtonColor}
          backgroundOnActive={ExerciseUI.site.accentColor}
          backgroundOnHover={ExerciseUI.site.transparentAccentColor}
          color="white"
          onClick={this.onClick}
          fontSize={this.props.fontSize}
          height={this.props.height}
          width={this.props.width}
          scaleOnHover={1.02}
          scaleOnActive={1}
          borderRadius={this.props.borderRadius}
        >
          {ExerciseUI.user.isMember && <i className='fa fa-lock'/>}
          {!ExerciseUI.user.isMember && !this.showFreeAccountButton && <CrownIcon margin="0 5px 0 0"/>}
          {this.message}
        </Button>
        {
          !AppUI.userIsSignedInAndNotGuest &&
            <Link
              className='underline'
              to={{name: 'onboarding.sign_in'}}
            >
              <Text fontSize={10} color="#333">{this.showFreeAccountButton ? 'Already have an Account? Sign in' : 'Already a Member? Sign in'}</Text>
            </Link>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(NoAccessButton);