import React from 'react';
import { observer } from 'mobx-react';
import { autorun, observable } from 'mobx';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import { setCookieValue } from '@seedlang/utils';

const Wrapper = styled.div`

`;

@observer
class AssignMembershipGroup extends React.Component {
  @observable membershipGroupWasAssigned = false;

  constructor(props) {
    super(props);
    autorun(() => {
      if (!this.membershipGroupWasAssigned && (AppUI.userIsLoaded || AppUI.user.signedOut)) {
        this.membershipGroupWasAssigned = true;
        this.setMembershipGroupAndRedirect();
      }
    })
  }

  async setMembershipGroupAndRedirect() {
    // Ensure that the MembershipOptions does not get outdated data
    AppUI.membershipGroupStore.clearShowData();

    if (AppUI.userIsLoaded) {
      await AppUI.userGroupStore.updatePromise({data: {membershipGroupId: this.props.params.membershipGroupId}, ids: {userGroupId: AppUI.user.currentUserGroup.id}});

      // Must wait for the updated user to be loaded before redirecting, otherwise the MembershipOptions might have outdated data
      await AppUI.loadUserPromise()
    }

    setCookieValue('assign_membership_group_id', this.props.params.membershipGroupId);

    AppUI.routeStore.routeToNamed('memberships.index');
  }

  render () {
    return (
      <Wrapper>
      </Wrapper>
    );
  }
}

export default AssignMembershipGroup;
