import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import TagCategoryCreate from 'pages/creator/tags/tag_category_create';
import TagCreate from 'pages/creator/tags/tag_create';
import autobind from 'autobind-decorator';
import { TagCategoryStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import DeleteButton from 'components/button/delete_button';
import { flexCenter } from '@seedlang/style_mixins';
import { TagStore } from '@seedlang/stores';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceSelect from 'components/form/in_place_select';
import { AppUI } from "@seedlang/state";
import ReactTooltip from 'react-tooltip';
import InfoTooltip from "components/info_tooltip";
import { startCase } from "lodash";
import BackEndExplainer from "pages/creator/back_end_explainer";
import Modal from 'components/modal';
import { isPresent, isBlank } from '@seedlang/utils';
import TagCategorySettings from './tag_category_settings';
import TagCategoryIndicators from './tag_category_indicators';
import Filter from "components/backend_filter/filter";


const Wrapper = styled.div`
`;

const TagCategory = styled.div`
  ${flexCenter()}
  background: white;
  padding: 10px;
  background: #d6d6d6;
`;

const TagCategoryWrapper = styled.div`
  margin-bottom: 20px;
`;

const TagWrapper = styled.div`
  background: #FFF;
  padding: 10px;
`;

const Delete = styled.div`
  width: 50px;
`;

const TagCategoryName = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 16px;
  display: flex;
`;

const TagName = styled.div`
  flex: 1;
  font-size: 16px;
  display: flex;
`;

const Position = styled.div`
  width: 50px;
`;

const Tag = styled.div`
  ${flexCenter()}
  padding-bottom: 5px;
`;

const Publish = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  width: 110px;
`;

const SelectOnlyOne = styled.div`
  width: 120px;
`;

const NavigationSettings = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const SelectCategory = styled.div`
  width: 180px;
  select {
    font-size: 12px;
  }
`;

const Counts = styled.div`
  font-size: 12px;
  margin-right: 10px;
  color: #808080;
`;

@observer
class TagsIndex extends React.Component {
  @observable selectedTagCategoryId = null;

  constructor(props) {
    super(props);
    this.loadTags();
  }

  @autobind loadTags() {
    TagCategoryStore.clearFilter();
    TagCategoryStore.getIndex({ids: {groupId: this.props.params.groupId}});
  }

  @autobind onDeleteTagCategory(id) {
    TagCategoryStore.destroy({ids: {tagCategoryId: id}}, this.loadTags);
  }

  @autobind onDeleteTag(id) {
    TagStore.destroy({ids: {tagId: id}}, this.loadTags);
  }

  @computed get selectedTagCategory() {
    return TagCategoryStore.indexData.find(item => item.id === this.selectedTagCategoryId);
  }

  render() {
    return (
      <Wrapper>
        {
          isPresent(this.selectedTagCategoryId) &&
            <TagCategorySettings 
              tagCategory={this.selectedTagCategory} 
              onCloseModal={() => this.selectedTagCategoryId = null}
              onUnmount={this.loadTags}
            />
        }
        <BackEndExplainer>These tags can be attached to posts and exercises and used to filter them by users.</BackEndExplainer>
        <TagCategoryCreate
          groupId={this.props.params.groupId}
          afterChange={this.loadTags}
        />
        <Filter
          store={TagCategoryStore}
          queryStrings={{include_count: true}}
          filters={[
            {
              type: 'boolean',
              name: 'is_worksheet_tag',
              label: 'Posts',
            },
            {
              type: 'boolean',
              name: 'is_exercise_tag',
              label: 'Exercises',
            }
          ]}
        />
        {
          TagCategoryStore.indexData.map(item => {
            return (
              <TagCategoryWrapper
                key={item.id}
              >
                <TagCategory>
                  <Position>
                    <InPlaceText
                      model="tag_categories"
                      field="position"
                      defaultValue={item.position}
                      id={item.id}
                      type="number"
                      step="1"
                      afterChange={this.loadTags}
                    />
                  </Position>
                  <TagCategoryName>
                    {
                      item.isAutomatic &&
                      <InfoTooltip icon='magic' position="right" margin="0 5px 0 0">
                        <div>These automatic filters are based on the user's <b>exercise</b> progress,</div>
                        <div>and cannot be assigned manually to worksheets or exercises.</div>
                      </InfoTooltip>
                    }
                    <InPlaceText
                      model="tag_categories"
                      field="name"
                      defaultValue={item.name}
                      id={item.id}
                      afterChange={this.loadTags}
                    />
                  </TagCategoryName>
                  <NavigationSettings>
                    <button
                      className="button-primary"
                      style={{background: "#e0e0e0"}}
                      onClick={() => this.selectedTagCategoryId = item.id}
                    >
                      <i className="fa fa-gear"/>
                      Settings
                    </button>
                    <TagCategoryIndicators
                      tagCategory={item}
                    />
                  </NavigationSettings>
                  <Publish>
                    <InPlaceCheckbox
                      model="tag_categories"
                      field="published"
                      value={item.published}
                      id={item.id}
                      afterChange={this.loadTags}
                      icon="check"
                    >
                      Publish
                    </InPlaceCheckbox>
                  </Publish>
                  <Delete>
                    <DeleteButton
                      onConfirm={() => this.onDeleteTagCategory(item.id)}
                      message="Delete this category? (all tags will also be deleted)"
                      className="fa-block right"
                      disabled={item.isAutomatic}
                    />
                  </Delete>
                </TagCategory>
                <TagWrapper>
                  {
                    item.tags.map(tag => {
                      return (
                        <Tag
                          key={tag.id}
                        >
                          <Position>
                            <InPlaceText
                              model="tags"
                              field="position"
                              defaultValue={tag.position}
                              id={tag.id}
                              type="number"
                              step="1"
                              afterChange={this.loadTags}
                            />
                          </Position>
                          {/* <Position>
                            {tag.overallPosition}
                          </Position> */}
                          <TagName>
                            {
                              tag.isAutomatic &&
                              <InfoTooltip icon='magic' position="right" margin="0 5px 0 0">
                                <div>{`This tag will show a user's "${startCase(tag.slug.replace('_', ' '))}" posts.`}</div>
                              </InfoTooltip>
                            }
                            <InPlaceText
                              model="tags"
                              field="name"
                              defaultValue={tag.name}
                              id={tag.id}
                              afterChange={this.loadTags}
                              hideEditable={tag.level}
                              blockClickToEdit={tag.level}
                            />
                          </TagName>
                          {
                            !item.isAutomatic &&  
                              <Counts>
                                {`${item.isExerciseTag ? `${tag.exercisesCount || '0'} exercises`: ''}${item.isExerciseTag && item.isWorksheetTag ? ' - ': ''}${item.isWorksheetTag ? `${tag.worksheetsCount || '0'} posts`: ''}`}
                              </Counts>
                          }
                          {
                            !item.isAutomatic && !tag.level &&
                              <SelectCategory>
                                
                                    <InPlaceSelect
                                      model="tags"
                                      field="tag_category_id"
                                      value={item.id}
                                      options={TagCategoryStore.indexData.map(item => [item.id, item.name])}
                                      id={tag.id}
                                      afterChange={this.loadTags}
                                      disabled={tag.level}
                                    />
                              </SelectCategory>
                          }
                          <Publish>
                            <InPlaceCheckbox
                              model="tags"
                              field="published"
                              value={tag.published}
                              id={tag.id}
                              afterChange={this.loadTags}
                              icon="check"
                            >
                              Publish
                            </InPlaceCheckbox>
                          </Publish>
                          <Delete>
                            <DeleteButton
                              onConfirm={() => this.onDeleteTag(tag.id)}
                              className="fa-block right"
                              disabled={item.isAutomatic || tag.level}
                              showTooltip={tag.level}
                              message={tag.level ? "Level tags cannot be deleted, but they can be unpublished." : "Delete this tag?"}
                            />
                          </Delete>
                        </Tag>
                      )
                    })
                  }
                  {
                    !item.isAutomatic && item.slug !== 'level' &&
                      <TagCreate
                        tagCategoryId={item.id}
                        groupId={this.props.params.groupId}
                        afterChange={this.loadTags}
                      />
                  }
                </TagWrapper>
              </TagCategoryWrapper>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TagsIndex);
