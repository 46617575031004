import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import { WorksheetStore, CustomTabStore } from '@seedlang/stores';
import CustomTabEdit from 'pages/creator/site/custom_tab_edit';
import Spinner from 'components/spinner';
import styled from '@emotion/styled';
import { Link } from 'react-router';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
`;

@observer
class WorksheetCustomTabEdit extends React.Component {

  constructor(props) {
    super(props);
    AppUI.scrollToPageTop();
    if (!CustomTabStore.hasIndexData) {
      CustomTabStore.getIndex();
    }
    if (!WorksheetStore.hasShowData) {
      this.getWorksheet();
    }
  }

  @autobind getWorksheet() {
    WorksheetStore.getShow({ ids: { worksheetId: this.props.params.worksheetId }});
  }

  @computed get worksheetCustomTab() {
    if (!WorksheetStore.hasShowData || !isPresent(this.props.params.worksheetCustomTabId)) return null;
    return WorksheetStore.showData.worksheetCustomTabs.find(tab => tab.id === this.props.params.worksheetCustomTabId);
  }

  @computed get customTab() {
    if (!CustomTabStore.hasIndexData || !isPresent(this.worksheetCustomTab)) return null;
    return CustomTabStore.indexData.find(tab => tab.id === this.worksheetCustomTab.customTabId);
  }

  render() {
    if (!WorksheetStore.hasShowData || !CustomTabStore.hasIndexData) {
      return <Spinner />;
    }

    if (!isPresent(this.worksheetCustomTab) || !isPresent(this.customTab)) {
      return <div>Custom tab not found</div>;
    }

    return (
      <Wrapper>
        <div className='breadcrumbs'>
          <div>
            <Link
              to={{name: "creator.groups.worksheets.index", params: {groupId: this.props.params.groupId}}}
            >
              Posts
            </Link>
          </div>
          <i className='fa fa-angle-double-right' />
          <div>
            <Link
              to={{name: "creator.groups.worksheets.edit", params: {worksheetId: WorksheetStore.showData.id, groupId: this.props.params.groupId}}}
            >
              {WorksheetStore.showData.name}
            </Link>
          </div>
          <i className='fa fa-angle-double-right' />
          <div>
            {this.customTab.title} Custom Tab
          </div>
        </div>
        <CustomTabEdit
          customTab={this.customTab}
          worksheetCustomTab={this.worksheetCustomTab}
          groupId={this.props.params.groupId}
        />
      </Wrapper>
    );
  }
}

export default WorksheetCustomTabEdit;
