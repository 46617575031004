import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Button from 'components/button/button';
import { AppUI, ExerciseUI } from '@seedlang/state';
import { isPresent, isBlank } from '@seedlang/utils';
import { Theme } from "@seedlang/constants";
import { computed } from "mobx";
import { Link } from "react-router";
import { startCase } from "lodash";
import NoAccessButton from "components/worksheet/no_access_button";
import autobind from 'autobind-decorator';


const Wrapper = styled.div`
  padding: 20px;
  background: white;
  border-radius: ${props => props.borderRadius};
`;

const AdminLink = styled.div`
  margin-top: 5px;
  font-size: 11px;
  text-decoration: underline;
  text-align: center;
  padding: 5px;
`;

const Table = styled.table`
  width: 100%;
  td {
    padding: 5px;
    vertical-align: middle;
  }
  td.clickable {
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
`;

const RSSLink = styled.span`
  display: flex;
  font-size: 14px;
  align-items: center;
  margin: 0 10px;
  color: #333333;
  cursor: pointer;
  span {
    text-decoration: underline;
  }
  i {
    margin-right: 5px;
  }
  span:hover {
    text-decoration: none;
  }
  justify-content: ${props => props.justifyContent};
`;

@observer
class DownloadLinkIndex extends React.Component {

  @computed get worksheet() {
    if (isPresent(this.props.worksheet)) {
      return this.props.worksheet;
    } else if (ExerciseUI.hasWorksheet) {
      return ExerciseUI.worksheet;
    }
  }

  @autobind showDownloadButton(downloadLink) {
    return (AppUI.siteIsDefault || (!downloadLink.blocked && !downloadLink.blockedUntilMigrated));
  }

  render () {
    return (
      <Wrapper borderRadius={this.props.borderRadius}>
        {
          isPresent(this.worksheet) &&
            <Table className={`${AppUI.layout.isDesktop ? 'table-striped' : ''} table-hover table-responsive`}>
              <tbody>
                {
                  this.worksheet.downloadLinks.map(item => {
                    return (
                      <React.Fragment key={item.id}>
                        <tr>
                          <td width='20px'>
                            <i className={`fa fa-${item.icon || 'link'}`} style={{margin: "5px 10px 5px 20px"}}/>
                          </td>
                          <td
                            style={{display: 'flex', minHeight: '40px', justifyContent: "space-between"}}
                            className={this.showDownloadButton(item) ? 'clickable' : ''}
                          >
                            <span
                              onClick={this.showDownloadButton(item) ? () => {
                                const link = document.createElement('a');
                                link.href = item.text;
                                link.download = item.title;
                                link.target = "_blank";
                                link.rel = "noopener noreferrer";
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                              } : undefined}
                              style={{flex: 1}}
                            >
                              {`${startCase(item.title.toLowerCase())}${isPresent(item.subtitle) ? ' - ' : ''}${item.subtitle || ''}`}
                            </span>
                            {
                              !item.blocked && isPresent(item.podcastTitle) && !AppUI.layout.isMobile &&
                                <Link
                                  to={{name: "settings.rss_feed"}}
                                  target='_blank'
                                >
                                  <RSSLink>
                                    <i className='fa fa-rss' /><span>Also available as a podcast feed</span>
                                  </RSSLink>
                                </Link>
                            }
                          </td>
                          <td width='240px'>
                            {
                              !AppUI.siteIsDefault && item.blocked &&
                                <Link
                                  to={ExerciseUI.worksheet.accessWithoutMembership ? "onboarding.register" : "memberships.index"}
                                  style={{margin: "5px"}}
                                >
                                  <Button
                                    height="30px"
                                    fontSize="14px"
                                    color="#FFF"
                                    borderRadius="5px"
                                    background={"#AAA"}
                                    scaleOnHover={AppUI.layout.isMobile ? null : "1.03"}
                                    backgroundOnActive={Theme.gray}
                                    width='98%'
                                    iconMarginRight={AppUI.layout.isMobile ? '0px' : '3px'}
                                  >
                                    <i className={`fa fa-lock`}/>{AppUI.layout.isMobile ? '' : ExerciseUI.worksheet.accessWithoutMembership ? 'Create Free Account to Try' : 'Upgrade to Unlock'}
                                  </Button>
                                </Link>
                            }
                            {
                              !AppUI.siteIsDefault && item.blockedUntilMigrated && !item.blocked &&
                                <Link
                                  to="settings.billing"
                                  style={{margin: "5px"}}
                                >
                                  <Button
                                    height="30px"
                                    fontSize="14px"
                                    color="#FFF"
                                    borderRadius="5px"
                                    background={AppUI.site.accentColor || "#D8D8D8"}
                                    scaleOnHover={AppUI.layout.isMobile ? null : "1.03"}
                                    backgroundOnActive={AppUI.site.accentColor || "#D8D8D8"}
                                    width='98%'
                                    iconMarginRight={AppUI.layout.isMobile ? '0px' : '3px'}
                                  >
                                    <i className={`fa fa-lock`}/>{AppUI.layout.isMobile ? '' : "Switch Payment to our Platform"}
                                  </Button>
                                </Link>
                            }
                            {
                              this.showDownloadButton(item) &&
                                <a
                                  href={item.text}
                                  download={item.title}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{margin: "5px"}}
                                  key={Math.random()}
                                >
                                  <Button
                                    height="30px"
                                    width='98%'
                                    fontSize="14px"
                                    color="#FFF"
                                    borderRadius="5px"
                                    background={AppUI.site.accentColor || "#716f6f"}
                                    scaleOnHover={AppUI.layout.isMobile ? null : "1.03"}
                                    backgroundOnActive={Theme.gray}
                                    iconMarginRight={AppUI.layout.isMobile ? '0px' : '3px'}
                                  >
                                    <i className={`fa fa-download`}/>{AppUI.layout.isMobile ? '' : 'Download'}
                                  </Button>
                                </a>
                            }
                          </td>
                      </tr>
                        {
                          !item.blocked && isPresent(item.podcastTitle) && AppUI.layout.isMobile &&
                            <tr>
                              <td colSpan='3'>
                                <Link
                                  to={{name: "settings.rss_feed"}}
                                  target='_blank'
                                >
                                  <RSSLink justifyContent='flex-end'>
                                    <i className='fa fa-rss' /><span>Also available as a podcast feed</span>
                                  </RSSLink>
                                </Link>
                              </td>
                            </tr>
                        }
                      </React.Fragment>
                    )
                  })
                }
              </tbody>
            </Table>
        }
        {
          ExerciseUI.user.anyAdmin && this.worksheet && this.worksheet.groupId &&
          <AdminLink>
            <Link
              to={{name: 'creator.groups.worksheets.edit', params: {groupId: this.worksheet.groupId, worksheetId: this.worksheet.id}}}
            >
              Admin Link
            </Link>
          </AdminLink>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(DownloadLinkIndex);
