import styled from '@emotion/styled';
import { computed } from 'mobx';
import { AppUI } from "@seedlang/state";
import { isBlank, isPresent } from "@seedlang/utils";
import autobind from "autobind-decorator";
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { isFunction } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';

const TagDisplay = styled.div`
  background: #FFF;
  color: #343535;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 16px;
  margin: 5px 5px 0 0;
  border-radius: 15px;
  font-weight: bold;
  border: 1px solid #cbcccb;
  cursor: ${props => props.isClickable ? 'pointer' : 'default'};
  &:hover {
    box-shadow: ${props => props.isClickable ? '0 0 6px rgba(33,33,33,.2)' : ''}; 
  }
  &:active {
    box-shadow: ${props => props.isClickable ? 'inset 0 0 6px rgba(33,33,33,.2)' : ''};
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: ${props => props.width};
  justify-content: space-between;
`;

const TagDisplayWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const EpisodeCode = styled.div`
  font-size: 14px;
  color: #616161;
  margin-top: 5px;
  text-wrap: nowrap;
`;

@observer
class Tags extends React.Component {

  static defaultProps = {
    width: '100%',
  }

  @autobind onClickWorksheetTag(tags) {
    if (!AppUI.site.showWorksheetFilter || isBlank(AppUI.user.currentUserGroup)) { return }
    if (isPresent(tags)) {
      const tagIds = tags?.map(tag => tag.id).join(',');
      AppUI.user.currentUserGroup.set('worksheetTagIds', tagIds);
      AppUI.userGroupStore.update({
        ids: {userGroupId: AppUI.user.currentUserGroup?.id},
        data: {worksheetTagIds: tagIds}
      }, this.afterClickTag);
    }
  }

  @autobind onClickWorksheetLevels() {
    this.onClickWorksheetTag(this.props.worksheet.levelTags);
  }

  @autobind afterClickTag() {
    AppUI.loadUser();
    if (isFunction(this.props.onUpdateFilter)) {
      this.props.onUpdateFilter();
    }
  }

  @computed get levelTags() {
    if (AppUI.routeStore.routeName === 'videos.index') {
      return this.props.worksheet?.levelTags.filter(item => item.displayOnVideoWorksheets);
    } else if (AppUI.routeStore.routeName === 'podcasts.index') {
      return this.props.worksheet?.levelTags.filter(item => item.displayOnPodcastWorksheets);
    } return this.props.worksheet?.levelTags;
  }

  @computed get tags() {
    if (isPresent(this.props.worksheet)) {
      if (isPresent(AppUI.routeStore.routeName)) {
        return this.props.worksheet?.tagsForRouteName(AppUI.routeStore.routeName);
      }
      return this.props.worksheet?.orderedTags;
    } return [];
  }

  render() {
    return (
      <Wrapper width={this.props.width}>
        <TagDisplayWrapper>
            {
              isPresent(this.levelTags) &&
                <TagDisplay
                  key={`${this.props.worksheet.id}-level-tags`}
                  onClick={this.onClickWorksheetLevels}
                  isClickable={AppUI.site.showWorksheetFilter && isPresent(AppUI.user.currentUserGroup)}
                >
                  {this.props.worksheet.levelTagsFormatted}
                </TagDisplay>
            }
            {
              isPresent(this.tags) && this.tags.map((tag) => {
                if (tag?.name && tag?.tagCategoryPublished && !tag.level) {
                  return (
                    <TagDisplay
                      key={tag.id}
                      onClick={() => this.onClickWorksheetTag([tag])}
                      isClickable={AppUI.site.showWorksheetFilter && isPresent(AppUI.user.currentUserGroup)}
                    >
                      {tag.name}
                    </TagDisplay>
                  )
                } else {
                  return null;
                }
              })
            }
        </TagDisplayWrapper>
         {
            this.props.withEpisodeCode && isPresent(this.props.worksheet.episodeCode) &&
            <EpisodeCode>{this.props.worksheet.episodeCode}</EpisodeCode>
          }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Tags);
