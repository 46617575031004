import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import DeleteButton from 'components/button/delete_button';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceText from 'components/form/in_place_text';
import { observer } from 'mobx-react';
import NavigationItemCreate from 'pages/builder/navigation_items/navigation_item_create';
import React from 'react';
import { Link } from "react-router";
import { isBlank } from '@seedlang/utils';
import ReactTooltip from 'react-tooltip';

const Wrapper = styled.div`
 
`;

@observer
class NavigationItemIndex extends React.Component {

  constructor(props) {
    super(props);
    this.getNavigationItems();
    if (!AppUI.membershipTypeStore.hasIndexData) {
      AppUI.membershipTypeStore.getIndex();
    }
  }

  @autobind getNavigationItems() {
    AppUI.navigationItemStore.getIndex();
  }

  onNavItemDelete(id) {
    AppUI.navigationItemStore.destroy({ ids: { navigationItemId: id }}, this.getNavigationItems);
  }

  @autobind membershipTypeNames(navigationItem) {
    if (!AppUI.membershipTypeStore.hasIndexData) { return '' }
    if (isBlank(navigationItem.membershipTypeIds)) {
      return "Any";
    }
    return navigationItem.membershipTypeIds.split(',').map(id => AppUI.membershipTypeStore.indexData.find(mt => mt.id === id)?.name).join(', ');
  }

  render() {
    return (
      <Wrapper>
        <NavigationItemCreate
          siteId={AppUI.site.id}
          afterCreate={this.getNavigationItems}
        />
        <table className="table-wrapper admin-table">
          <thead>
          <tr>
          <th width="40" />
            <th>Position</th>
            <th>Name</th>
            <th>Path</th>
            <th>Signed In</th>
            <th>Signed Out</th>
            <th>Header</th>
            <th>Footer</th>
            <th>Settings</th>
            <th>Memberships</th>
            <th width="40" />
          </tr>
          </thead>
          <tbody>
          {
            AppUI.navigationItemStore.indexData.map((item) => {
              return (
                <tr key={item.id}>
                  <td>
                    <Link to={{name: 'creator.groups.navigation_items.edit', params: { navigationItemId: item.id, groupId: this.props.params.groupId }}}>
                      <i className='fa fa-chevron-right fa-block' />
                    </Link>
                  </td>
                  <td>
                    <InPlaceText
                      id={item.id}
                      defaultValue={item.position}
                      model="navigation_items"
                      field="position"
                      afterChange={this.getNavigationItems}
                    />
                  </td>
                  <td>
                    <InPlaceText
                      id={item.id}
                      defaultValue={item.name}
                      model="navigation_items"
                      field="name"
                    />
                  </td>
                  <td>
                    <InPlaceText
                      id={item.id}
                      defaultValue={item.path}
                      model="navigation_items"
                      field="path"
                    />
                  </td>
                  <td>
                    <InPlaceCheckbox
                      id={item.id}
                      value={item.showWhenSignedIn}
                      model="navigation_items"
                      field="show_when_signed_in"
                      icon="check"
                    />
                  </td>
                  <td>
                    <InPlaceCheckbox
                      id={item.id}
                      value={item.showWhenSignedOut}
                      model="navigation_items"
                      field="show_when_signed_out"
                      icon="check"
                    />
                  </td>
                  <td>
                    <InPlaceCheckbox
                      id={item.id}
                      value={item.headerLink}
                      model="navigation_items"
                      field="header_link"
                      icon="check"
                    />
                  </td>
                  <td>
                    <InPlaceCheckbox
                      id={item.id}
                      value={item.footerLink}
                      model="navigation_items"
                      field="footer_link"
                      icon="check"
                    />
                  </td>
                  <td>
                    <InPlaceCheckbox
                      id={item.id}
                      value={item.settingsLink}
                      model="navigation_items"
                      field="settings_link"
                      icon="check"
                    />
                  </td>
                  <td>
                    {
                      isBlank(item.membershipTypeIds) ? "Any" : 
                        <div
                          data-tip
                          data-for={item.id}
                        >
                          {item.membershipTypeIds?.split(',').length}
                          <ReactTooltip
                            id={item.id}
                            place="top"
                            type="dark"
                            effect="solid"
                          >
                            {this.membershipTypeNames(item)}
                          </ReactTooltip>
                        </div>
                    }
                  </td>
                  <td>
                    <DeleteButton
                      onConfirm={() => this.onNavItemDelete(item.id)}
                      message={"Delete this navigation item?"}
                      className="fa-block right"
                    />
                  </td>
                </tr>
              );
            })
          }
          </tbody>
        </table>
      </Wrapper>
    );
  }
}

export default NavigationItemIndex;
