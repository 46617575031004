import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceSelect from 'components/form/in_place_select';
import { BannerNoticeStore } from '@seedlang/stores';
import { AppUI } from '@seedlang/state';
import InPlaceDatePicker from 'components/form/in_place_date_picker';
import autobind from 'autobind-decorator';
import { MembershipTypeStore, BannerNoticeMembershipTypeStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';
import InfoTooltip from "components/info_tooltip";
import Spinner from "components/spinner";
import { Link } from 'react-router';
import { isBlank } from '@seedlang/utils';
@observer
class BannerNoticeEdit extends React.Component {

  @computed get bannerNotice() {
    return BannerNoticeStore.showData;
  }

  constructor(props) {
    super(props);
    this.getBannerNotice();
    this.loadMembershipTypes();
  }

  @autobind getBannerNotice() {
    BannerNoticeStore.getShow({ids: {bannerNoticeId: this.props.params.bannerNoticeId}});
  }

  @autobind onChange() {
    BannerNoticeStore.getShow({ids: {bannerNoticeId: this.props.params.bannerNoticeId}});
  }

  @autobind loadMembershipTypes() {
    MembershipTypeStore.getIndex({});
  }

  @autobind membershipExists(membershipType) {
    if (this.bannerNotice && isPresent(this.bannerNotice.bannerNoticeMembershipTypes)) {
      return this.bannerNotice.bannerNoticeMembershipTypes.find(item => item.membershipType.id === membershipType.id);
    } return false;
  }

  @autobind onToggleMembership(membershipType) {
    if (this.membershipExists(membershipType)) {
      const bannerNoticeMembershipType = this.bannerNotice.bannerNoticeMembershipTypes.find(item => item.membershipType.id === membershipType.id);
      BannerNoticeMembershipTypeStore.destroy({ids: {bannerNoticeMembershipTypeId: bannerNoticeMembershipType.id}}, this.onChange);
    } else {
      BannerNoticeMembershipTypeStore.create({data: {membership_type_id: membershipType.id, banner_notice_id: this.bannerNotice.id}}, this.onChange);
    }
  }

  @computed get pathOptions() {
    const options = []
    if (AppUI.site.signedInHomePageRoute !== AppUI.site.signedOutHomePageRoute) {
      options.push([AppUI.site.signedInHomePageRoute, "Home (signed in)"]);
      options.push([AppUI.site.signedOutHomePageRoute, "Home (signed out)"]);
    } else {
      options.push([AppUI.site.signedInHomePageRoute, "Home"]);
    }
    options.push(["podcasts.index", "Podcasts"]);
    options.push(["videos.index", "Videos"]);
    return options;
  }

  render() {
    return (
      <div className='creator-edit'>
        <div className='breadcrumbs-wrapper'>
          <div className='breadcrumbs'>
            <Link to={{name: "creator.banner_notices.index", params: {groupId: AppUI.site.groupId}}}>Banner Notices</Link>
            <i className='fa fa-angle-double-right' />
            <div className='current'>
              {BannerNoticeStore.showData.title}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <fieldset>
              <legend>Title</legend>
              <InPlaceText
                defaultValue={BannerNoticeStore.showDataField('title')}
                model="banner_notices"
                field="title"
                id={BannerNoticeStore.showDataField('id')}
              />
            </fieldset>
          </div>
          <div className={AppUI.siteIsDefault ? "col-xs-3" : "col-xs-6"}>
            <fieldset>
              <legend>{AppUI.siteIsDefault ? "Web Published" : "Published"}</legend>
              {
                !BannerNoticeStore.showData.webPublished &&
                  <InfoTooltip icon='warning'>
                    This banner notice has not been published.
                  </InfoTooltip>
              }
              <InPlaceCheckbox
                icon="check"
                model="banner_notices"
                field="web_published"
                value={BannerNoticeStore.showDataField('webPublished')}
                id={BannerNoticeStore.showDataField('id')}
                afterChange={this.onChange}
              />
            </fieldset>
          </div>
          {
            AppUI.siteIsDefault &&
              <div className="col-xs-3">
                <fieldset>
                  <legend>Mobile Published</legend>
                  <InPlaceCheckbox
                    icon="check"
                    model="banner_notices"
                    field="mobile_published"
                    value={BannerNoticeStore.showDataField('mobilePublished')}
                    id={BannerNoticeStore.showDataField('id')}
                  />
                </fieldset>
              </div>
          }
        </div>
        <div className="row">
          <div className="col-xs-12">
            <fieldset>
              <legend>Text</legend>
              <InPlaceText
                richText
                defaultValue={BannerNoticeStore.showDataField('text')}
                model="banner_notices"
                field="text"
                inputType="textarea"
                id={BannerNoticeStore.showDataField('id')}
              />
            </fieldset>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <fieldset>
              <legend>Button Text</legend>
              <InPlaceText
                defaultValue={BannerNoticeStore.showDataField('buttonText')}
                model="banner_notices"
                field="button_text"
                id={BannerNoticeStore.showDataField('id')}
              />
            </fieldset>
          </div>
          <div className="col-xs-6">
            <fieldset>
              <legend>Button URL</legend>
              <InPlaceText
                defaultValue={BannerNoticeStore.showDataField('url')}
                model="banner_notices"
                field="url"
                id={BannerNoticeStore.showDataField('id')}
              />
            </fieldset>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-3">
            <fieldset>
              <legend>Expire At</legend>
              <InPlaceDatePicker
                model="banner_notices"
                field="expire_at"
                value={BannerNoticeStore.showDataField('expireAt')}
                id={BannerNoticeStore.showDataField('id')}
              />
            </fieldset>
          </div>
          <div className="col-xs-3">
            <fieldset>
              <legend># Sessions Until Expiration <InfoTooltip>This banner will be shown this number of time to a user before disappearing.</InfoTooltip></legend>
              <InPlaceText
                number
                model="banner_notices"
                field="number_sessions_until_expiration"
                defaultValue={BannerNoticeStore.showDataField('numberSessionsUntilExpiration')}
                id={BannerNoticeStore.showDataField('id')}
              />
            </fieldset>
          </div>
          {
            !AppUI.siteIsDefault &&
              <div className="col-xs-6">
                <fieldset>
                  <legend>Page</legend>
                  {
                    isBlank(BannerNoticeStore.showData.route) &&
                      <InfoTooltip icon='warning'>
                        This banner notice has not been assigned to a page.
                      </InfoTooltip>
                  }
                  <InPlaceSelect
                    includeBlank
                    model="banner_notices"
                    field="route"
                    options={this.pathOptions}
                    afterChange={this.onChange}
                    value={BannerNoticeStore.showDataField('route')}
                    id={BannerNoticeStore.showDataField('id')}
                  />
                </fieldset>
              </div>
          }
        </div>
        {
          !AppUI.siteIsDefault &&
            <div className="row">
              <div className="col-xs-6">
                <fieldset>
                  <legend>Payment Migration Status</legend>
                  <InPlaceSelect
                    includeBlank
                    model="banner_notices"
                    field="payment_migration_status"
                    options={[
                      ["stripe_payment_setup_started", "Credit Card Details Started, But Not Completed"],
                      ["waiting_for_patreon_cancellation", "Credit Card Details Completed, Waiting for Cancellation"],
                      ["waiting_for_patreon_expiry", "Patreon Cancelled, Waiting for Expiration"],
                      ["completed", "Migration Complete"],
                    ]}
                    afterChange={this.onChange}
                    value={BannerNoticeStore.showDataField('paymentMigrationStatus')}
                    id={BannerNoticeStore.showDataField('id')}
                  />
                </fieldset>
              </div>
              <div className="col-xs-6">
                <fieldset>
                  <legend>Membership Types</legend>
                  {
                    MembershipTypeStore.requestCounter > 0 &&
                      <Spinner
                        background={AppUI.site?.accentColor}
                      />
                  }
                  <div className='row'>
                    <InPlaceCheckbox
                      id={this.bannerNotice.id}
                      value={this.bannerNotice.showToSignedOutUser}
                      afterChange={this.getBannerNotice}
                      model='banner_notices'
                      field='show_to_signed_out_user'
                      icon="check"
                    >
                      Signed Out Users
                    </InPlaceCheckbox>
                  </div>
                  <div className='row'>
                    <InPlaceCheckbox
                      id={this.bannerNotice.id}
                      value={this.bannerNotice.showToNonMembers}
                      afterChange={this.getBannerNotice}
                      model='banner_notices'
                      field='show_to_non_members'
                      icon="check"
                    >
                      Signed In Users with No Membership
                    </InPlaceCheckbox>
                  </div>
                  {
                    MembershipTypeStore.indexData.map(item => {
                      return (
                        <div className='row' key={item.id}>
                          <InPlaceCheckbox
                            value={this.membershipExists(item)}
                            icon="check"
                            onChange={() => this.onToggleMembership(item)}
                          >
                            {item.name}
                          </InPlaceCheckbox>
                        </div>
                      )
                    })
                  }
                </fieldset>
              </div>
            </div>
        }
      </div>
    );
  }
}

export default BannerNoticeEdit;
