import React from 'react';
import { observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { PageStore } from '@seedlang/stores';
import DeleteButton from 'components/button/delete_button';
import Paginator from 'components/paginator';
import Modal from 'components/modal';
import PageCreate from 'pages/builder/pages/page_create';
import { Link } from 'react-router';
import CheckIfTrue from 'components/check_if_true';

@observer
class PageIndex extends React.Component {
  static defaultProps = {
    whiteLabeled: false
  }

  @observable showCreate = false;

  componentDidMount() {
    PageStore.getIndex();
  }

  componentWillUnmount() {
    PageStore.clearIndexData();
  }

  onDelete(id) {
    PageStore.destroy({ ids: { pageId: id } });
  }

  @computed get pages() {
    return this.props.whiteLabeled ? PageStore.indexData.filter(item => item.siteId === this.props.siteId) : PageStore.indexData;
  }

  render() {
    return (
      <div className="page-index">
        {
          !this.props.hideCreateButton && this.showCreate &&
          <Modal
            onCloseModal={() => this.showCreate = false}
          >
            <PageCreate />
          </Modal>
        }
        <div style={{display: 'flex', marginBottom: '10px'}}>
          <div style={{width: 150}}>
            {
              !this.props.hideCreateButton && (
                <button
                  className='gray-button'
                  onClick={() => this.showCreate = true}
                >
                  <i className='fa fa-plus' />
                  Create
                </button>
              )
            }
          </div>
        </div>
        <table className="table-wrapper admin-table">
          <thead>
            <tr>
              <th width="40"></th>
              <th width="40"></th>
              <th>Title</th>
              <th>Slug</th>
              <th>{this.props.whiteLabeled ? 'Path' : 'Site'}</th>
              <th>Memberships</th>
              <th width="40"></th>
            </tr>
          </thead>
          <tbody>
            {
              this.pages.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={this.props.whiteLabeled ? { name: 'creator.groups.site_pages.edit', params: { groupId: this.props.params.groupId, pageId: item.id } } : { name: 'builder.page.edit', params: { pageId: item.id } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td>
                    <Link
                      to={{ name: 'page.show', params: { pageId: item.id } }}
                      className="on-click"
                    >
                      <i className='fa fa-external-link fa-block' />
                    </Link>
                    </td>
                    <td>
                      <div className="text">
                        {item.title}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.slug}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {this.props.whiteLabeled ? ('/' + item.slug) : item.siteName}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.membershipTypes.map(item => item.name).join(",")}
                      </div>
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={this.onDelete.bind(this, item.id)}
                        message="Delete this page?"
                        className="fa-block right"
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={PageStore}
        />
      </div>
    );
  }
}

export default PageIndex;
