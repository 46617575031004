import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  padding: 20px;
  background: #f1f1f1;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  h3 {
    line-height: 1.5;
  }
`;

@observer
class ReviewExplainer extends React.Component {

  render() {
    return (
      <Wrapper>
        <h3>What are Seedlang Reviews?</h3>
        <p>
        Seedlang is a free flashcard website that specializes in video flashcards. On Seedlang, you can create your own flashcards (or Reviews), and organize them into your own decks.
        </p>
        <h3>How does the integration between Seedlang and this website work?</h3>
        <p>
          When you create a Review on this website, it will show up on Seedlang. You can sign in to Seedlang using the same username and password that you use on this website.
        </p>
        <h3>Why didn't you include the Reviews on this website?</h3>
        <p>
          Seedlang is constantly working to improve the quality of their flashcards, which is a very large project that warrants its own app and website.
        </p>
        <Button
          background={Theme.blue}
          onClick={() => {
            window.open('https://seedlang.com', '_blank');
          }}
        >
          Visit Seedlang
        </Button>
      </Wrapper>
    );
  }
}

export default ReviewExplainer;
