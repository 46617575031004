import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import { Link } from 'react-router';
import { TreeNodeStore } from '@seedlang/stores';
import { TreeStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import autobind from 'autobind-decorator';
import ConceptSearch from 'components/concept_search';
import Text from 'components/text';
import { TreeNodeConceptStore, TreeNodeGrammarConceptStore, ConceptStore } from '@seedlang/stores';
import DeleteButton from 'components/button/delete_button';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import Modal from 'components/modal';
import ConceptCreate from 'pages/builder/concepts/concept_create';
import { isPresent } from '@seedlang/utils';
import InPlaceImageUpload from 'components/form/in_place_image_upload';
import InPlaceSelect from 'components/form/in_place_select';
import InPlaceDatePicker from 'components/form/in_place_date_picker';
import { LevelStore } from '@seedlang/stores';
import { WordTypeStore } from '@seedlang/stores';
import { DeckRulebookStore } from '@seedlang/stores';
import { DeckStore } from '@seedlang/stores';
import { noop, some, without } from 'lodash';
import styled from '@emotion/styled';
import ReactTooltip from "react-tooltip";
import { Constants, Theme } from '@seedlang/constants';
import { AppUI } from '@seedlang/state';
import Alert from 'components/alert';
import InfoTooltip from "components/info_tooltip";

const Wrapper = styled.div`
  .tree-node-edit {
    font-size: 14px;
    margin-top: 10px;
    .modal {
      max-height: 600px;
      overflow: auto;
    }
    .position {
      .col-xs-6 {
        padding: 0;
      }
    }
    .compact {
      td {
        padding: 3px;
      }
    }
    .concept-header {
      display: flex;
      .concept-search {
        flex: 1;
        margin-left: 10px;
      }
      .gray-button {
        margin-bottom: 10px;
      }
    }
  }
`;

const ReviewedUser = styled.div`
  font-size: 12px;
  padding-left: 18px;
  background: #FFF;
`;

const DescriptionButton = styled.div`
  margin: 15px 0 10px 0;
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  border-radius: 10px;
  background: #d3d3d382;
  padding: 5px;
`

@observer
class TreeNodeEdit extends React.Component {
  @observable showCreateConcept = false;
  @observable showCreateGrammarConcept = false;
  @observable decksCreatedNotice = false;
  @observable showLegacyDescription = false;
  @observable showConceptGenerationAlert = false;
  @observable hideSentencesCountWarning = false;
  @observable addedGrammarConcept = false;
  @observable deletedGrammarConcept = false;
  @observable toggledConceptDescriptions = [];

  @computed get nodeHasDecks() {
    return !TreeNodeStore.hasShowData ||
      !TreeNodeStore.showData.deckRulebook ||
      ['vocab-trainer'].indexOf(TreeNodeStore.showData.deckRulebook.slug) === -1;
  }
  @computed get treeId() {
    return this.props.params.treeId || TreeNodeStore.showData.treeId;
  }

  @computed get treeRowId() {
    return this.props.params.treeRowId || TreeNodeStore.showData.treeRowId;
  }

  @computed get treeModuleId() {
    return this.props.params.treeModuleId || TreeNodeStore.showData.treeModuleId;
  }

  @computed get treeModule() {
    if (TreeStore.hasShowData) {
      return TreeStore.showData.treeModules.find(item => item.id === this.treeModuleId);
    }
    return {};
  }

  @computed get hasTreeModule() {
    return isPresent(this.treeModule);
  }

  constructor(props) {
    super(props);
    this.loadTreeNode();
    if (!LevelStore.hasIndexData) {
      LevelStore.getIndex();
    }
    if (!WordTypeStore.hasIndexData) {
      WordTypeStore.getIndex();
    }
    if (!DeckRulebookStore.hasIndexData) {
      DeckRulebookStore.getIndex();
    }
  }

  @computed get myRow() {
    if (this.hasTreeModule) {
      return this.treeModule.treeRows.find(item => item.id === TreeNodeStore.showData.treeRowId)
    }
    return null;

  }

  @autobind createDecks() {
    if (!this.decksAreMissing) { return }
    TreeNodeStore.createDecks({ids: {treeNodeId: this.props.params.treeNodeId}}, this.afterCreateDecks);
  }

  @autobind afterCreateDecks() {
    this.decksCreatedNotice = true;
    this.loadTreeNode();
  }

  @autobind loadTreeNode() {
    TreeNodeStore.getShow({queryStrings: {expanded: true}, ids: {treeNodeId: this.props.params.treeNodeId}}, this.afterGetTreeNode);
  }

  @autobind afterGetTreeNode(resp) {
    TreeNodeStore.afterGetShow(resp);
    if (!TreeStore.hasIndexData) {
      TreeStore.getShow({ids: {treeId: this.treeId}});
    }
    if (!resp.grammarDescriptionOverride) {
      resp.treeNodeGrammarConcepts.forEach(item => {
        this.toggledConceptDescriptions.push(item.id);
      });
    } else {
      this.showLegacyDescription = true;
    }
  }

  @autobind onClickRating(value, id) {
    DeckStore.update({ids: {deckId: id}, data: {our_rating: value}}, noop);
  }

  @autobind loadTreeAndNode() {
    TreeNodeStore.getShow({ids: {treeNodeId: this.props.params.treeNodeId}});
  }

  @autobind onCreateTreeNodeConcept(concept) {
    TreeNodeConceptStore.create({data: {tree_node_id: this.props.params.treeNodeId, concept_id: concept.id}}, this.loadTreeNode);
  }

  @autobind onCreateTreeNodeGrammarConcept(concept) {
    TreeNodeGrammarConceptStore.create({data: {tree_node_id: this.props.params.treeNodeId, concept_id: concept.id}}, this.loadTreeNode);
    this.addedGrammarConcept = true;
  }

  @autobind onDeleteTreeNodeConcept(id) {
    TreeNodeConceptStore.destroy({ids: {treeNodeConceptId: id}}, this.loadTreeNode);
  }

  @autobind onDeleteTreeNodeGrammarConcept(id) {
    TreeNodeGrammarConceptStore.destroy({ids: {treeNodeGrammarConceptId: id}}, this.loadTreeNode);
    this.deletedGrammarConcept = true;
  }

  @autobind onChangeRowPosition(position) {
    TreeNodeStore.moveToRow({ids: {treeNodeId: this.props.params.treeNodeId}, data: {position: position}}, this.loadTreeNode);
  }

  @autobind onChangeTreeModule(id) {
    TreeNodeStore.moveToTreeModule({ids: {treeNodeId: this.props.params.treeNodeId}, data: {tree_module_id: id}}, this.loadTreeAndNode);
  }

  @autobind createGrammarConcepts() {
    TreeNodeStore.createGrammarConcepts({ids: {treeNodeId: this.props.params.treeNodeId}}, this.loadTreeNode);
    this.showConceptGenerationAlert = true;
  }

  @autobind destroyConcept(id) {
    ConceptStore.destroy({ ids: { conceptId: id } }, this.loadTreeNode);
  }

  @computed get isGrammarTreeNode() {
    return TreeNodeStore.hasShowData && TreeNodeStore.showData.treeNodeType === 'grammar';
  }

  @computed get isConceptTreeNode() {
    return TreeNodeStore.hasShowData && TreeNodeStore.showData.treeNodeType === 'concept';
  }

  @autobind toggleConceptDescription(treeNodeGrammarConcepts) {
    if (this.toggledConceptDescriptions.indexOf(treeNodeGrammarConcepts.id) === -1) {
      this.toggledConceptDescriptions.push(treeNodeGrammarConcepts.id)
    } else {
      this.toggledConceptDescriptions = without(this.toggledConceptDescriptions, treeNodeGrammarConcepts.id);
    }
  }

  @autobind afterChangeGrammarDescriptionOverride() {
    this.toggledConceptDescriptions = [];
    this.showLegacyDescription = false;
    this.loadTreeNode();
  }

  @computed get decksAreMissing() {
    return TreeNodeStore.hasShowData && TreeNodeStore.showData.treeNodeConcepts.map(item => item.concept?.id).sort().join(",") !== TreeNodeStore.showData.treeNodeDecks.map(item => item.deck.concept?.id).sort().join(",");
  }


  render() {
    return (
      <Wrapper>
        <div className='tree-node-edit'>
          {
            (this.showCreateConcept || this.showCreateGrammarConcept) &&
              <Modal
                top='50%'
                onCloseModal={() => {
                  this.showCreateConcept = false;
                  this.showCreateGrammarConcept = false;
                }}
              >
                <ConceptCreate
                  trivia={TreeStore.showData?.slug === 'trivia'}
                  grammarConcept={this.showCreateGrammarConcept}
                  languageId={TreeNodeStore.showData?.languageId}
                  routeToAfterCreate={this.showCreateGrammarConcept ? 'builder.concepts.edit' : 'builder.trees.concepts.edit'}
                  routeParams={{
                    treeId: this.treeId,
                    treeNodeId: this.props.params.treeNodeId,
                    treeRowId: this.treeRowId,
                    treeModuleId: this.treeModuleId,
                  }}
                  data={{
                    level_id: TreeNodeStore.showData.levelId,
                    tree_node_id: this.props.params.treeNodeId,
                    sentences_maintain_ordering: true,
                    automatic_associations: false,
                  }}
                />
              </Modal>
          }
          {
            TreeNodeStore.hasShowData &&
              <div className='inner'>
                <div className='breadcrumbs-wrapper'>
                  <div className='breadcrumbs'>
                    <Link
                      to={{name: 'builder.trees.index'}}
                    >
                      Trees
                    </Link>
                    <i className='fa fa-angle-double-right' />
                    {
                      this.treeId &&
                        <span>
                          <Link
                            to={{name: 'builder.trees.edit', params: {treeId: this.treeId}}}
                          >
                            {TreeStore.hasShowData && TreeStore.showData.name}
                          </Link>
                          <i className='fa fa-angle-double-right' />
                          <Link
                            to={{name: 'builder.tree_modules.edit', params: {treeModuleId: this.treeModuleId, treeId: this.treeId}}}
                          >
                            {this.hasTreeModule && this.treeModule.name}
                          </Link>
                          <i className='fa fa-angle-double-right' />
                        </span>
                    }
                    <div className='current'>
                      {TreeNodeStore.showData.name}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xs-3'>
                    <fieldset>
                      <legend>Name</legend>
                      <InPlaceText
                        id={TreeNodeStore.showData.id}
                        defaultValue={TreeNodeStore.showData.name}
                        field="name"
                        model="tree_nodes"
                        afterChange={this.loadTreeNode}
                      />
                      {
                        !this.isGrammarTreeNode && AppUI.user.targetLanguageId === TreeNodeStore.showData.languageId &&
                          <Link
                            to={{name: 'stories.show', params: {treeNodeId: this.props.params.treeNodeId}}}
                            style={{fontSize: '12px', textDecoration: 'underline'}}
                            target="_blank"
                          >
                            View Tree Node
                          </Link>
                      }
                      {
                        this.isGrammarTreeNode && AppUI.user.targetLanguageId === TreeNodeStore.showData.languageId &&
                          <Link
                            to={{name: 'grammar.show.slug', params: {grammarNodeId: this.props.params.treeNodeId, slug: TreeNodeStore.showData.name}}}
                            style={{fontSize: '12px', textDecoration: 'underline'}}
                            target="_blank"
                          >
                            View Tree Node
                          </Link>
                      }
                    </fieldset>
                  </div>
                  {
                    this.isGrammarTreeNode &&
                    <div className='col-xs-3'>
                      <fieldset>
                        <legend>Short Name <InfoTooltip>Displayed in the grammar index</InfoTooltip></legend>
                        <InPlaceText
                          id={TreeNodeStore.showData.id}
                          defaultValue={TreeNodeStore.showData.shortName}
                          field="short_name"
                          model="tree_nodes"
                          afterChange={this.loadTreeNode}
                          placeholder="Add a Short Name"
                        />
                      </fieldset>
                    </div>
                  }
                  {
                    !this.isGrammarTreeNode &&
                      <div className='col-xs-3'>
                        <fieldset>
                          <legend>Grammar</legend>
                          <InPlaceText
                            id={TreeNodeStore.showData.id}
                            defaultValue={TreeNodeStore.showData.grammarTitle}
                            field="grammar_title"
                            model="tree_nodes"
                            afterChange={this.loadTreeNode}
                          />
                        </fieldset>
                      </div>
                  }
                  <div className='col-xs-3'>
                    <fieldset>
                      <legend>Settings</legend>
                      <div>
                      <InPlaceCheckbox
                        icon="check"
                        id={TreeNodeStore.showData.id}
                        value={TreeNodeStore.showData.webPublished}
                        field="web_published"
                        model="tree_nodes"
                        afterChange={this.loadTreeNode}
                      >
                        Web Published
                      </InPlaceCheckbox>
                      </div>
                      <div>
                      <InPlaceCheckbox
                        icon="check"
                        id={TreeNodeStore.showData.id}
                        value={TreeNodeStore.showData.mobilePublished}
                        field="mobile_published"
                        model="tree_nodes"
                        afterChange={this.loadTreeNode}
                      >
                        Mobile Published
                      </InPlaceCheckbox>
                      </div>
                    </fieldset>
                  </div>
                  <div className='col-xs-3'>
                    <fieldset style={{position: 'relative'}}>
                      <legend>Approved</legend>
                        <div>
                          <InPlaceCheckbox
                            model="tree_nodes"
                            field="reviewed1"
                            value={TreeNodeStore.showData.reviewed1}
                            id={TreeNodeStore.showData.id}
                            icon="thumbs-up"
                            afterChange={this.loadTreeNode}
                          >
                            Didactics
                          </InPlaceCheckbox>
                        </div>
                        {
                          isPresent(TreeNodeStore.showData.reviewed1User) &&
                            <ReviewedUser>
                              {TreeNodeStore.showData.reviewed1User.name}
                            </ReviewedUser>
                        }
                        <div>
                          <InPlaceCheckbox
                            model="tree_nodes"
                            field="reviewed2"
                            value={TreeNodeStore.showData.reviewed2}
                            id={TreeNodeStore.showData.id}
                            icon="thumbs-up"
                            afterChange={this.loadTreeNode}
                          >
                            English
                          </InPlaceCheckbox>
                        </div>
                        {
                          isPresent(TreeNodeStore.showData.reviewed2User) &&
                            <ReviewedUser>
                              {TreeNodeStore.showData.reviewed2User.name}
                            </ReviewedUser>
                        }
                    </fieldset>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xs-3'>
                    <fieldset>
                      <legend>Module</legend>
                      {
                        TreeStore.hasShowData &&
                          <InPlaceSelect
                            value={TreeNodeStore.showData.treeModuleId}
                            options={TreeStore.showData.treeModules.map(item => [item.id, item.name])}
                            onChange={this.onChangeTreeModule}
                          />
                      }
                    </fieldset>
                  </div>
                  <div className='col-xs-2'>
                    <fieldset>
                      <legend>Web Published At</legend>
                      <InPlaceDatePicker
                        id={TreeNodeStore.showData.id}
                        model="tree_nodes"
                        field="web_published_at"
                        value={TreeNodeStore.showData.webPublishedAt}
                        afterChange={this.loadTreeNode}
                      />
                    </fieldset>
                  </div>
                  <div className='col-xs-2'>
                    <fieldset>
                      <legend>Mobile Published At</legend>
                      <InPlaceDatePicker
                        id={TreeNodeStore.showData.id}
                        model="tree_nodes"
                        field="mobile_published_at"
                        value={TreeNodeStore.showData.mobilePublishedAt}
                        afterChange={this.loadTreeNode}
                      />
                    </fieldset>
                  </div>
                  <div className='col-xs-2'>
                    <fieldset>
                      <legend>Row (Max {this.treeModule ? this.treeModule.maxTreeRowPosition : null})</legend>
                      {
                        this.myRow &&
                          <InPlaceText
                            onSubmit={this.onChangeRowPosition}
                            defaultValue={this.myRow.position}
                            type="number"
                            max={TreeStore.showData.maxTreeRowPosition}
                            afterChange={this.loadTreeNode}
                          />
                      }
                    </fieldset>
                  </div>
                  <div className='col-xs-2'>
                    <fieldset>
                      <legend>Col (Max {this.myRow ? this.myRow.maxTreeNodePosition : null})</legend>
                      {
                        isPresent(this.myRow) &&
                          <InPlaceText
                            id={TreeNodeStore.showData.id}
                            defaultValue={TreeNodeStore.showData.position}
                            field="position"
                            model="tree_nodes"
                            afterChange={this.loadTreeNode}
                            type="number"
                            max={this.myRow.maxTreeNodePosition}
                          />
                      }
                    </fieldset>
                  </div>
                  {
                    !this.isGrammarTreeNode &&
                      <div className='col-xs-4'>
                        <fieldset>
                          <legend>Image</legend>
                          <InPlaceImageUpload
                            model="images"
                            image={TreeNodeStore.showData.image}
                            onChange={this.loadTreeNode}
                            querystring={{
                              imageable_id: TreeNodeStore.showDataField('id'),
                              imageable_type: 'TreeNode'
                            }}
                          />
                        </fieldset>
                      </div>
                  }
                </div>
                <div className='row'>
                  <div className='col-xs-3'>
                    <fieldset>
                      <legend>Type</legend>
                      {
                        !TreeNodeStore.showData.treeNodeType &&
                          <i className='fa fa-warning' />
                      }
                      <InPlaceSelect
                        includeBlank
                        clickToEdit
                        id={TreeNodeStore.showData.id}
                        value={TreeNodeStore.showData.treeNodeType}
                        field="tree_node_type"
                        model="tree_node"
                        options={[['concept', 'Concept'], ['grammar', 'Grammar'], ['trivia', 'Trivia'], ['vocab', 'Vocab']]}
                        afterChange={this.loadTreeNode}
                      />
                    </fieldset>
                  </div>
                  {
                    !this.isGrammarTreeNode &&
                      <div className='col-xs-3'>
                        <fieldset>
                          <legend>Deck Rulebook (optional)</legend>
                          <InPlaceSelect
                            includeBlank
                            clickToEdit
                            id={TreeNodeStore.showData.id}
                            value={TreeNodeStore.showData.deckRulebook && TreeNodeStore.showData.deckRulebook.id}
                            field="deck_rulebook_id"
                            model="tree_node"
                            options={DeckRulebookStore.indexData.map(item => [item.id, item.name])}
                            afterChange={this.loadTreeNode}
                          />
                        </fieldset>
                      </div>
                  }
                  <div className='col-xs-3'>
                    <fieldset>
                      <legend>Level</legend>
                      <InPlaceSelect
                        clickToEdit
                        includeBlank
                        id={TreeNodeStore.showData.id}
                        value={TreeNodeStore.showData.level && TreeNodeStore.showData.level.id}
                        field="level_id"
                        model="tree_node"
                        options={LevelStore.indexData.map(item => [item.id, item.nameWithAbbreviation])}
                        afterChange={TreeNodeStore.reloadShow.bind(TreeNodeStore)}
                      />
                    </fieldset>
                  </div>
                  {
                    !this.isGrammarTreeNode &&
                      <div className='col-xs-3'>
                        <fieldset>
                          <legend>Word Type</legend>
                          <InPlaceSelect
                            includeBlank
                            clickToEdit
                            id={TreeNodeStore.showData.id}
                            value={TreeNodeStore.showData.wordType && TreeNodeStore.showData.wordType.id}
                            field="word_type_id"
                            model="tree_node"
                            options={WordTypeStore.indexData.map(item => [item.id, item.name])}
                            afterChange={TreeNodeStore.reloadShow.bind(TreeNodeStore)}
                          />
                        </fieldset>
                      </div>
                  }
                </div>
                {
                  this.isConceptTreeNode &&
                    <div className='row'>
                      <div className='col-xs-12'>
                        <fieldset>
                          <legend>Description (Markdown)</legend>
                          <InPlaceText
                            markdown
                            inputType="textarea"
                            model="tree_nodes"
                            id={TreeNodeStore.showData.id}
                            defaultValue={TreeNodeStore.showData.descriptionMd}
                            field="description_md"
                          />
                        </fieldset>
                      </div>
                    </div>
                }
                {
                  (this.isConceptTreeNode || this.isGrammarTreeNode) &&
                    <div className='row'>
                      <div className='col-xs-12'>
                        <fieldset>
                          <legend>Grammar Description</legend>
                          {
                            !this.isGrammarTreeNode &&
                              <div className='concept-header'>
                                {
                                  TreeNodeStore.showData.treeNodeGrammarConcepts.length === 0 &&
                                  <span data-tip data-for={"generateConcepts"} style={{marginRight: 4}}>
                                  <button
                                    className='gray-button'
                                    onClick={this.createGrammarConcepts}
                                    style={{marginRight: "5px"}}
                                  >
                                    <i className='fa fa-plus'/>
                                    Generate Concepts
                                  </button>
                                  <ReactTooltip
                                    place="right"
                                    type="dark"
                                    effect="solid"
                                    id={"generateConcepts"}
                                    class="custom-tooltip"
                                  >
                                    Generate the concepts from the grammar description.
                                  </ReactTooltip>
                                </span>
                                }
                                <button
                                  className='gray-button'
                                  onClick={() => {
                                    this.showCreateGrammarConcept = true
                                  }}
                                >
                                  <i className='fa fa-plus'/>
                                  Create
                                </button>
                                {
                                  AppUI.userIsLoaded &&
                                  <ConceptSearch
                                    placeholder="Search for Concepts for the Grammar Description"
                                    onSubmit={this.onCreateTreeNodeGrammarConcept}
                                    languageId={Constants.LANGUAGE_ABBREVIATIONS.indexOf(TreeNodeStore.showData.languageId) !== -1 ? TreeNodeStore.showData.languageId : null}
                                    displayField={"nameAndLevelAndSentencesCount"}
                                  />
                                }
                              </div>
                          }
                          {
                            this.addedGrammarConcept &&
                              <Alert
                                showCloseButton
                                background={Theme.green}
                                onClose={() => this.addedGrammarConcept = false}
                                style={{
                                  margin: "0 0 10px 0",
                                }}
                              >
                                This concept was also added to all the decks of the tree node.
                              </Alert>
                          }
                          {
                            this.deletedGrammarConcept &&
                              <Alert
                                showCloseButton
                                background={Theme.green}
                                onClose={() => this.deletedGrammarConcept = false}
                                style={{
                                  margin: "0 0 10px 0",
                                }}
                              >
                                This concept was also deleted from all the decks of the tree node.
                              </Alert>
                          }
                          {
                            this.showConceptGenerationAlert &&
                              <Alert
                                showCloseButton
                                onClose={() => this.showConceptGenerationAlert = false}
                                style={{
                                  margin: "0 0 10px 0",
                                }}
                              >
                                Please make sure that the new concept... (1) matches the legacy description, (2) is not a duplicate of an existing one, (3) doesn't need to be split into several concepts.
                              </Alert>
                          }
                          <table className="table-wrapper">
                            <thead>
                            <tr>
                              <th width="20" />
                              <th width="50">Position</th>
                              <th style={{ textAlign: 'center' }}>Name & description</th>
                              <th width="50">#Sent</th>
                              <th width="80">Level</th>
                              <th width="40"></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                              TreeNodeStore.showData.treeNodeGrammarConcepts.length === 0 &&
                              <tr>
                                <td colSpan="100%" style={{textAlign: 'center'}}>
                                  <Text italic fontSize="14px">It looks like you haven't added any concepts yet</Text>
                                </td>
                              </tr>
                            }
                            {
                              TreeNodeStore.hasShowData && TreeNodeStore.showData.treeNodeGrammarConcepts.map(item => {
                                return (
                                  <tr key={item?.id}>
                                    <td>
                                      {
                                        isPresent(item.concept) &&
                                          <Link
                                            to={{ name: 'builder.concepts.edit', params: { conceptId: item.concept?.id } }}
                                            className="on-click"
                                            target="_blank"
                                          >
                                            <i className="fa fa-chevron-right fa-block" />
                                          </Link>
                                      }
                                      {
                                        isPresent(item.concept?.youtubeUrl) &&
                                          <div
                                            data-tip
                                            data-for={`video-${item.id}`}
                                            style={{display: 'flex', justifyContent: 'center', fontSize: '16px', margin: '5px 0', color: '#333'}}
                                          >
                                            <i className="fa fa-youtube-play" />
                                            <ReactTooltip
                                              id={`video-${item.id}`}
                                              place="right"
                                              type="dark"
                                              effect="solid"
                                              class="custom-tooltip"
                                            >
                                              Has a Youtube Video
                                            </ReactTooltip>
                                          </div>
                                      }
                                    </td>
                                    <td>
                                      <InPlaceText
                                        id={item.id}
                                        defaultValue={item.position}
                                        field="position"
                                        model="tree_node_grammar_concepts"
                                        type="number"
                                        afterChange={this.loadTreeNode}
                                      />
                                    </td>
                                    <td>
                                      {
                                       TreeNodeStore.showData.treeNodeType !== 'grammar' &&
                                          <Text heading='3'>
                                            <InPlaceText
                                              id={item.concept?.id}
                                              defaultValue={item.concept?.displayNameLine1WithCase}
                                              field="display_name_line_1"
                                              model="concept"
                                              afterChange={this.loadTreeNode}
                                              fontSize="20px"
                                              placeholder={"Display Name (Line 1)"}
                                              warning="This changes the display name of this concept everywhere it appears."
                                            />
                                          </Text>
                                      }
                                      {
                                       TreeNodeStore.showData.treeNodeType === 'grammar' &&
                                        <span
                                          data-tip
                                          data-for={`concept-name-${item.id}`}
                                        >
                                          <Text bold>
                                            {item.concept?.displayNameLine1WithCase}
                                          </Text>
                                          <ReactTooltip
                                            place="top"
                                            type="dark"
                                            effect="solid"
                                            id={`concept-name-${item.id}`}
                                            class="custom-tooltip"
                                          >
                                            <div>The concept's name won't appear in the grammar section.</div>
                                            <div>You should modify the tree node's name to change it in the grammar section.</div>
                                          </ReactTooltip>
                                        </span>
                                      }
                                      <Text
                                        italic
                                        fontSize="16px"
                                      >
                                        <InPlaceText
                                          id={item.concept?.id}
                                          defaultValue={item.concept?.displayNameLine2}
                                          field="display_name_line_2"
                                          model="concept"
                                          afterChange={this.loadTreeNode}
                                          fontSize="20px"
                                          placeholder={"Display Name (Line 2) [optional]"}
                                          warning="This changes subtitle of this concept everywhere it appears."
                                        />
                                      </Text>
                                      {
                                       !this.isGrammarTreeNode && TreeNodeStore.showData?.grammarDescriptionOverride &&
                                          <DescriptionButton
                                            onClick={this.toggleConceptDescription.bind(this,item)}
                                          >
                                            {`${this.toggledConceptDescriptions.indexOf(item.id) !== -1 ? 'Hide' : 'Show'} description`}
                                          </DescriptionButton>
                                      }
                                      {
                                        (this.toggledConceptDescriptions.indexOf(item.id) !== -1 || !TreeNodeStore.showData?.grammarDescriptionOverride) &&
                                          <InPlaceText
                                            richText
                                            inputType="textarea"
                                            model="concepts"
                                            id={item.concept?.id}
                                            defaultValue={item.concept?.longDescription}
                                            field="long_description"
                                            placeholder="Long description"
                                            warning="This changes the description of this concept everywhere it appears (inside decks, in the dictionary...)."
                                          />
                                      }
                                    </td>
                                    <td>{item.concept?.sentencesCount}</td>
                                    <td style={{ textAlign: 'center' }}>{item.concept?.level && item.concept?.level?.abbreviation}</td>
                                    <td>
                                      <DeleteButton
                                        onConfirm={() => this.onDeleteTreeNodeGrammarConcept(item?.id)}
                                        message="Remove this grammar concept from the tree node?"
                                        className="fa-block right"
                                        margin="0 0 10px 0"
                                      />
                                      {
                                        item?.concept && item?.concept.sentencesCount === 0 && item?.concept.wordsCount === 0 && item?.concept.treeNodeConceptsCount <= 0 &&
                                          <DeleteButton
                                            onConfirm={() => this.destroyConcept(item?.concept.id)}
                                            message="DELETE this concept?"
                                            className="fa-block right"
                                            faIcon='fa-trash'
                                            iconColor={Theme.red}
                                          />
                                      }
                                    </td>
                                  </tr>
                                )
                              })
                            }
                            </tbody>
                          </table>
                          {
                            !this.isGrammarTreeNode && TreeNodeStore.hasShowData &&
                            <div style={{display: "flex", justifyContent: 'space-between', marginTop: "10px"}}>
                              <InPlaceCheckbox
                                icon="check"
                                model="tree_nodes"
                                field="grammar_description_override"
                                value={TreeNodeStore.showData.grammarDescriptionOverride}
                                id={TreeNodeStore.showData.id}
                                afterChange={this.afterChangeGrammarDescriptionOverride}
                              >
                                Use legacy description rather than grammar concepts in tree node grammar
                              </InPlaceCheckbox>
                              <InPlaceCheckbox
                                icon="check"
                                value={TreeNodeStore.showData.allDecksHaveGrammarDescriptionOverride}
                                onChange={() => TreeNodeStore.toggleAllDecksGrammarDescriptionOverride({ids: {treeNodeId: TreeNodeStore.showData.id}})}
                              >
                                Use legacy descriptions in all decks
                              </InPlaceCheckbox>
                            </div>

                          }
                          {
                            !this.isGrammarTreeNode &&
                              <DescriptionButton
                                onClick={() => this.showLegacyDescription = !this.showLegacyDescription}
                              >
                                {`${this.showLegacyDescription ? 'Hide' : 'Show'} description (legacy)`}
                              </DescriptionButton>
                          }
                          {
                            this.showLegacyDescription &&
                              <InPlaceText
                                richText
                                inputType="textarea"
                                model="tree_nodes"
                                id={TreeNodeStore.showData.id}
                                defaultValue={TreeNodeStore.showData.grammarDescription}
                                field="grammar_description"
                              />
                          }
                        </fieldset>
                      </div>
                    </div>
                }
                {
                  this.nodeHasDecks &&
                    <div className='row'>
                      <div className='col-xs-12'>
                        <fieldset>
                          <legend>Decks</legend>
                          {
                            this.decksCreatedNotice &&
                              <div className='alert'>
                                Decks created!
                              </div>
                          }
                          {
                            !this.isGrammarTreeNode &&
                              <div className='concept-header'>
                                {
                                  TreeNodeStore.hasShowData &&
                                  <button
                                    className='gray-button'
                                    onClick={this.createDecks}
                                    disabled={!this.decksAreMissing}
                                    data-tip
                                    data-for='missing-decks'
                                  >
                                    <i className='fa fa-plus'/>
                                    Create Missing Decks
                                    {
                                      !this.decksAreMissing &&
                                        <ReactTooltip
                                          place="right"
                                          type="dark"
                                          effect="solid"
                                          id='missing-decks'
                                          class="custom-tooltip"
                                        >
                                          <span style={{textTransform: 'none'}}>All concepts have decks already.</span>
                                        </ReactTooltip>
                                    }
                                  </button>
                                }
                              </div>
                          }
                          <table className="table-wrapper">
                            <thead>
                              <tr>
                                <th width="20" />
                                <th width="50">Position</th>
                                <th>Name</th>
                                <th>Reviewed</th>
                                <th width="40">WPubl</th>
                                <th width="40">MPubl</th>
                                <th width="40">Free</th>
                                <th colSpan="2">Total</th>
                                <th colSpan="2">Guest</th>
                                <th colSpan="2">Registered</th>
                                <th colSpan="2">Pro</th>
                                <th>Words</th>
                                <th>Cards</th>
                                <th>Tips</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                TreeNodeStore.hasShowData && TreeNodeStore.showData.treeNodeDecks.map(item => {
                                  return (
                                    <tr key={item.id}>
                                      <td>
                                        <Link
                                          to={{ name: 'builder.trees.decks.edit', params: { treeRowId: this.treeRowId, treeModuleId: this.treeModuleId, treeId: this.treeId, treeNodeId: this.props.params.treeNodeId, deckId: item.deck.id } }}
                                          className="on-click"
                                        >
                                          <i className="fa fa-chevron-right fa-block" />
                                        </Link>
                                      </td>
                                      <td>
                                        <InPlaceText
                                          id={item.id}
                                          defaultValue={item.position}
                                          field="position"
                                          model="tree_node_decks"
                                          type="number"
                                          afterChange={this.loadTreeNode}
                                        />
                                      </td>
                                      <td>
                                        {/*{item.deck && item.deck.name}*/}
                                        <InPlaceText
                                          id={item.deck.id}
                                          defaultValue={item.deck.name}
                                          field="name"
                                          model="decks"
                                          afterChange={this.loadTreeNode}
                                          submitOnEnter
                                        />
                                      </td>
                                      <td>
                                        <div className="text" style={{width: 60}}>
                                          <span data-tip data-for={`didactics-1-${item.id}`} style={{marginRight: 4}}>
                                            <InPlaceCheckbox
                                              model="decks"
                                              field="reviewed1"
                                              value={item.deck.reviewed1}
                                              id={item.deck.id}
                                              icon="thumbs-up"
                                            />
                                            <ReactTooltip
                                              place="top"
                                              type="dark"
                                              effect="solid"
                                              id={`didactics-1-${item.id}`}
                                              class="custom-tooltip"
                                            >
                                              Approved: Didactics 1
                                            </ReactTooltip>
                                          </span>
                                          <span data-tip data-for={`didactics-2-${item.id}`} style={{marginRight: 4}}>
                                            <InPlaceCheckbox
                                              model="decks"
                                              field="reviewed3"
                                              value={item.deck.reviewed3}
                                              id={item.deck.id}
                                              icon="thumbs-up"
                                            />
                                            <ReactTooltip
                                              place="top"
                                              type="dark"
                                              effect="solid"
                                              id={`didactics-2-${item.id}`}
                                              class="custom-tooltip"
                                            >
                                              Approved: Didactics 2
                                            </ReactTooltip>
                                          </span>
                                          <span data-tip data-for={`translation-${item.id}`} style={{marginRight: 4}}>
                                            <InPlaceCheckbox
                                              model="decks"
                                              field="reviewed2"
                                              value={item.deck.reviewed2}
                                              id={item.deck.id}
                                              icon="thumbs-up"
                                            />
                                            <ReactTooltip
                                              place="top"
                                              type="dark"
                                              effect="solid"
                                              id={`translation-${item.id}`}
                                              class="custom-tooltip"
                                            >
                                              Approved: English
                                            </ReactTooltip>
                                          </span>
                                        </div>
                                      </td>
                                      <td>
                                        <InPlaceCheckbox
                                          icon="check"
                                          id={item.deck.id}
                                          value={item.deck.webPublished}
                                          field="web_published"
                                          model="decks"
                                          afterChange={this.loadTreeNode}
                                        />
                                      </td>
                                      <td>
                                        <InPlaceCheckbox
                                          icon="check"
                                          id={item.deck.id}
                                          value={item.deck.mobilePublished}
                                          field="mobile_published"
                                          model="decks"
                                          afterChange={this.loadTreeNode}
                                        />
                                      </td>
                                      <td>
                                        <InPlaceCheckbox
                                          icon="check"
                                          id={item.deck.id}
                                          value={item.deck.openAccess}
                                          field="open_access"
                                          model="decks"
                                          afterChange={this.loadTreeNode}
                                        />
                                      </td>
                                      <td style={{background: 'whitesmoke'}}>
                                        <Link
                                          className='underline'
                                          to={{name: 'builder.decks.user_decks.index', params: {deckId: item.deck.id}}}
                                        >
                                          {item.deck.userDecksCount}
                                        </Link>
                                      </td>
                                      <td style={{background: 'whitesmoke'}}>
                                        {`${item.deck.percentageFinished}%`}
                                      </td>
                                      <td>
                                        {item.deck.guestUserDecksCount}
                                      </td>
                                      <td>
                                        {`${item.deck.percentageGuestFinished}%`}
                                      </td>
                                      <td style={{background: 'whitesmoke'}}>
                                        {item.deck.registeredUserDecksCount}
                                      </td>
                                      <td style={{background: 'whitesmoke'}}>
                                        {`${item.deck.percentageRegisteredFinished}%`}
                                      </td>
                                      <td>
                                        {item.deck.proUserDecksCount}
                                      </td>
                                      <td>
                                        {`${item.deck.percentageProFinished}%`}
                                      </td>
                                      <td>
                                        {item.deck.wordsCount}
                                      </td>
                                      <td>
                                        {item.deck.cardsCount}
                                      </td>
                                      <td>
                                        {item.deck.grammarTipsCount}
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                          {
                            this.isGrammarTreeNode &&
                              <Alert
                                background="#e0e0e0"
                                color="black"
                                style={{
                                  margin: "10px 0 0px 0",
                                  fontSize: '12px'
                                }}
                              >
                                {`If you want to add decks to a grammar section tree node, you have to add them to the `}
                                {
                                  TreeNodeStore.showData.treeNodeGrammarConcepts[0] &&
                                    <Link
                                      to={{name: 'builder.concepts.edit', params: {conceptId: TreeNodeStore.showData.treeNodeGrammarConcepts[0].concept?.id}}}
                                      className="on-click"
                                      target="_blank"
                                    >
                                    grammar concept.
                                    </Link>
                                }
                                {
                                  !TreeNodeStore.showData.treeNodeGrammarConcepts[0] &&
                                    "grammar concept."
                                }
                              </Alert>
                          }
                        </fieldset>
                      </div>
                    </div>
                }
                {
                  !this.isGrammarTreeNode && this.nodeHasDecks &&
                    <div className='row'>
                      <div className='col-xs-12'>
                        <fieldset>
                          <legend>Deck Concepts
                            <InfoTooltip position="right">
                              <div>These concepts hold the sentences used in the decks.</div>
                              <div>Create a concept and then generate the missing decks.</div>
                            </InfoTooltip>
                          </legend>
                          {
                            !this.hideSentencesCountWarning && TreeNodeStore.hasShowData && some(TreeNodeStore.showData.treeNodeConcepts.map(item => item.concept?.sentencesCount > 20)) &&
                            <Alert showCloseButton onClose={() => this.hideSentencesCountWarning = true}>One of the concepts has a lot of sentences! Are you sure you want to make a deck with this many sentences?</Alert>
                          }
                          <div className='concept-header'>
                            <button
                              className='gray-button'
                              onClick={() => {this.showCreateConcept = true}}
                            >
                              <i className='fa fa-plus' />
                              Create
                            </button>
                            {/*{*/}
                            {/*  AppUI.userIsLoaded &&*/}
                            {/*    <ConceptSearch*/}
                            {/*      onSubmit={this.onCreateTreeNodeConcept}*/}
                            {/*      languageId={Constants.LANGUAGE_ABBREVIATIONS.indexOf(TreeNodeStore.showData.languageId) !== -1 ? TreeNodeStore.showData.languageId : null}*/}
                            {/*      displayField={"nameAndLevel"}*/}
                            {/*    />*/}
                            {/*}*/}
                          </div>
                          <table className="table-wrapper">
                            <thead>
                              <tr>
                                <th width="20" />
                                <th width="50">Position</th>
                                <th>Name</th>
                                <th width="50">#Sent</th>
                                <th width="80">Level</th>
                                <th width="40"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                TreeNodeStore.hasShowData && TreeNodeStore.showData.treeNodeConcepts.map(item => {
                                  return (
                                    <tr key={item.id}>
                                      <td>
                                        <Link
                                          to={{ name: 'builder.trees.concepts.edit', params: { treeRowId: this.treeRowId, treeModuleId: this.treeModuleId, treeId: this.treeId, treeNodeId: this.props.params.treeNodeId, conceptId: item.concept.id } }}
                                          className="on-click"
                                        >
                                          <i className="fa fa-chevron-right fa-block" />
                                        </Link>
                                      </td>
                                      <td>
                                        <InPlaceText
                                          id={item.id}
                                          defaultValue={item.position}
                                          field="position"
                                          model="tree_node_concepts"
                                          type="number"
                                          afterChange={this.loadTreeNode}
                                        />
                                      </td>
                                      <td>{item.concept?.name}</td>
                                      <td>
                                        {item.concept?.sentencesCount}
                                        {
                                          item.concept?.sentencesCount > 20 &&
                                            <InfoTooltip icon="warning">This concept has a lot of sentences! Are you sure you want to make a deck out of it?</InfoTooltip>
                                        }
                                      </td>
                                      <td>{item.concept?.level && item.concept?.level?.abbreviation}</td>
                                      <td>
                                        <DeleteButton
                                          onConfirm={() => this.onDeleteTreeNodeConcept(item.id)}
                                          message="Remove this concept?"
                                          className="fa-block right"
                                        />
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </fieldset>
                      </div>
                    </div>
                }
              </div>
          }
        </div>
      </Wrapper>
    );
  }
}

export default TreeNodeEdit;
