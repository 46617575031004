import React from 'react';
import { observer } from 'mobx-react';
import { computed, autorun, observable } from 'mobx';
import { SiteStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';
import { isPresent } from '@seedlang/utils';
import NavigationItemIndex from 'pages/builder/navigation_items/navigation_item_index';

@observer
class SiteNavigation extends React.Component {
  @observable siteLoaded = false;

  constructor(props) {
    super(props);
    autorun(() => {
      if (isPresent(AppUI.site) && !this.siteLoaded) {
        this.siteLoaded = true;
        setTimeout(() => this.getSite(), 1000);
      }
    })
  }

  @computed get site() {
    return SiteStore.showData;
  }

  @autobind getSite() {
    SiteStore.getShow({ ids: { siteId: AppUI.site.id }});
  }

  render() {
    return (
      <div className="row">
        {
          SiteStore.hasShowData &&
            <div className="col-xs-12">
              <fieldset>
                <legend>Navigation</legend>
                <NavigationItemIndex
                  items={this.site.navigationItems}
                  {...this.props}
                />
              </fieldset>
            </div>
        }
      </div>
    );
  }
}

export default SiteNavigation;
