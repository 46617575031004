import React from 'react';
import { observer } from 'mobx-react';
import { autorun, computed, observable } from 'mobx';
import autobind from 'autobind-decorator';
import { AppUI, ExerciseUI } from '@seedlang/state';
import { isBlank, isPresent, pixify } from '@seedlang/utils';
import styled from '@emotion/styled';
import { last, min, noop } from 'lodash';
import Modal from 'components/modal';
import Toggle from "react-toggle";
import Text from "components/text";
import { Languages, Theme } from "@seedlang/constants";
import WorksheetTab from "components/worksheet/tab";
import { CustomTabStore } from "@seedlang/stores";
import BackToTopButton from 'components/button/back_to_top_button';
import WorksheetInfo from "components/worksheet/worksheet_info";
import WorksheetBackButton from "components/worksheet/breadcrumbs";
import { Link } from "react-router";
import WorksheetStore from "pages/creator/worksheets/worksheet_index";

const Wrapper = styled.div`
  margin-top: 10px;
  overflow: hidden;
  .chat-outline-icon {
    width: 13px;
    margin: 5px 3px 0 0;
    svg {
      fill: #333;
    }
  }
`;

const TopSection = styled.div`
  display: flex;
  @media (max-width: 999px) {
    flex-direction: column;
  }
  gap: 10px;
`;

const Navigation = styled.div`
  display: flex;
  align-items: ${props => props.alignItems};
  justify-content: space-between;
  flex-direction: ${props => props.flexDirection};
  margin-top: 5px;
  @media only screen and (max-width : 700px) {
    padding: 0px 10px;
  }
`;

const Tabs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${props => props.gap};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  width: 100%;
`;

const IconsWrapper = styled.div`
  display: flex;
  gap: 5px;
  padding: ${props => props.padding};
  width: ${props => props.width};
  justify-content: ${props => props.justifyContent};
  .fa {
    margin-right: 5px;
    margin-left: auto;
    color: rgb(59 59 59);
    cursor: pointer;
    margin: auto 5px 10px 0;
    font-size: 18px;
  }
  .not-allowed {
    cursor: not-allowed;
    color: gray;
  }
`;

const NoAccess = styled.div`
  flex: 1;
`;

const MediaSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const BackToTop = styled.div`
  background: ${props => props.background || '#716f6f'};
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  color: white;
  font-weight: bold;
  position: fixed;
  z-index: 1000;
  font-size: 30px;
  box-shadow: 2px 2px 4px 2px #00000052;
  left: ${props => pixify(props.left)};
  top: ${props => pixify(props.top)};
  cursor: pointer;
  &:hover {
    transform: scale(1.03);
  }
  &:active {
    transform: scale(0.97);
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  .react-toggle--checked .react-toggle-track{
    background: ${props => props.highlightColor};
  }
`;

const Note = styled.div`
  margin-top: 5px;
  font-size: 14px;
  color: #333;
  font-style: italic;
`;

const SettingsWrapper = styled.div`
  margin: 20px;
`;

@observer
class WorksheetShow extends React.Component {
  @observable showBackToTop = false;
  @observable worksheetBottom;
  @observable worksheetRight;
  @observable showUserSettings = false;
  @observable rerouted = false;

  constructor(props) {
    super(props);
    if (this.props.params.worksheetId) {
      this.getWorksheet();
    } else if (this.props.params.exerciseId) {
      this.getExercise();
    }
    this.disposer = autorun(() => {
      if (this.worksheetId) {
        AppUI.createEvent('worksheets - view worksheet', {worksheet_id: this.worksheetId});
      }
      if ((this.worksheetRoute || this.tabCount <= 1) && ExerciseUI.hasWorksheet && !this.rerouted) {
        this.routeToFirstTab();
      }
      if (AppUI.userIsLoaded || AppUI.user.signedOut) {
        this.checkWorksheetAccess();
      }
    });
  }

  componentDidMount() {
    if (!AppUI.layout.isMobile) {
      window.addEventListener('scroll', this.onScroll, true);
    }
  }

  componentWillUnmount() {
    ExerciseUI.worksheetStore.clearShowData();
    CustomTabStore.clearIndexData();
    this.disposer && this.disposer();
    if (!AppUI.layout.isMobile) {
      window.removeEventListener('scroll', this.onScroll);
    }
  }

  @computed get worksheetRoute() {
    return last(this.props.router.routes).name === `${ExerciseUI.worksheetNamespace}.show`;
  }

  @autobind routeToFirstTab() {
    console.log("routeToFirstTab");
    this.rerouted = true;
    ExerciseUI.worksheet.routeToFirstTab();
  }

  @autobind checkWorksheetAccess() {
    if (!ExerciseUI.hasWorksheetAccess) {
      AppUI.routeStore.routeToNamed('worksheets.index', {groupId: AppUI.site.groupId});
    }
  }

  @autobind onScroll() {
    // I changed this to appear after 400 pixels of scrolling
    if (isPresent(document.getElementsByClassName('app')) && document.getElementsByClassName('app')[0]?.scrollTop > 400) {
      this.showBackToTop = true;
      const worksheetBody = document.getElementById('worksheet-body');
      if (isPresent(worksheetBody)) {
        this.worksheetRight = worksheetBody.getBoundingClientRect().right;
        this.worksheetBottom = worksheetBody.getBoundingClientRect().bottom;
      }
    } else if (this.showBackToTop) {
      this.showBackToTop = false;
    }
  }

  @autobind goBackToTop() {
    // document.getElementsByClassName('app')[0].scrollTo({top: 0, behavior: 'smooth'});
    AppUI.scrollToPageTop('smooth', AppUI.layout.isMobile ? "video-player" : null);
  }

  @computed get vocabRoute() {
    return last(this.props.router.routes).name === `${ExerciseUI.worksheetNamespace}.vocab.show`;
  }

  @computed get exercisesRoute() {
    return last(this.props.router.routes).name.endsWith("exercises.show");
  }

  @computed get commentsRoute() {
    return last(this.props.router.routes).name === `${ExerciseUI.worksheetNamespace}.comments.show`;
  }

  @computed get showWorksheetLinks() {
    return ExerciseUI.hasWorksheet
      && (ExerciseUI.layout.viewportWidth > 999 || this.exercisesRoute)
      && ExerciseUI.worksheetStore.showData.exercises.filter(item => item.exerciseType.slug !== null).length > 0
      && (isBlank(ExerciseUI.worksheet.podcastEpisode) || this.exercisesRoute)
  }

  @computed get worksheetId() {
    if (ExerciseUI.exerciseStore.hasShowData && !this.props.params.worksheetId) {
      return ExerciseUI.exerciseStore.showData.worksheetId;
    }
    return this.props.params.worksheetId;
  }

  @autobind routeToComments() {
    ExerciseUI.routeStore.routeToNamed('worksheets.comments.show', {
      groupId: this.props.params.groupId,
      worksheetId: this.worksheetId,
      exerciseId: ExerciseUI.vocabExerciseId
    });
  }

  @autobind getWorksheet() {
    ExerciseUI.set('worksheetId', this.worksheetId);
    ExerciseUI.getWorksheet(this.worksheetId, this.afterGetWorksheet);
  }

  @autobind afterGetWorksheet(resp) {
    ExerciseUI.worksheetStore.afterGetShow(resp);
    // this.getCustomTabs(resp);
  }

  // @autobind getCustomTabs(resp) {
  //   CustomTabStore.clearFilter();
  //   CustomTabStore.getIndex({filters: {published: true, worksheet_id: this.worksheetId}});
  // }

  // @computed get customTabs() {
  //   if (!CustomTabStore.hasIndexData || !ExerciseUI.hasWorksheet) return [];
  //   return CustomTabStore.indexData.filter(item => 
  //     item.isVisibleToUser(AppUI.user) &&
  //     ((ExerciseUI.worksheet?.hasCustomTab(item.id)) || (isPresent(ExerciseUI.worksheet?.youtubeUrl) && item.showOnVideoWorksheets)|| (isPresent(ExerciseUI.worksheet?.podcastEpisode) && item.showOnPodcastWorksheets))
  //   );
  // }

  @autobind getExercise() {
    ExerciseUI.exerciseStore.getShow({ids: {exerciseId: this.props.params.exerciseId}}, this.afterGetExercise);
  }

  @autobind afterGetExercise(resp) {
    ExerciseUI.exerciseStore.setShowData(resp);
    this.getWorksheet();
  }

  @computed get numberOfButtons() {
    let count = 0;
    ['1', '2', '3'].map(num => {
        if (isPresent(ExerciseUI.worksheet[`transcript${num}Link`])){
          count += 1;
        }
      }
    );
    if (ExerciseUI.worksheet.hasPdf) {
      count += 1;
    }
    ['1', '2', '3', '4', '5', '6'].map(num => {
      if (isPresent(AppUI.user?.site[`downloadLinkTitle${num}`]) && isPresent(ExerciseUI.worksheet[`downloadLink${num}`])) {
        count += 1;
      }
    });
    return count;
  }

  @autobind toggleUserSetting(key) {
    const newValue = !ExerciseUI.user[key];
    ExerciseUI.user.set(key, newValue);
    const data = {};
    data[key] = newValue;
    ExerciseUI.authUserStore.update({data: data}, noop);
  }

  @computed get tabCount() {
    let res = 0;
    if (ExerciseUI.worksheet?.hasPublishedVocab) {
      res += 1;
    }
    if (ExerciseUI.hasWorksheet && ExerciseUI.worksheet?.exercises.length > 0) {
      res += 1;
    }
    if (ExerciseUI.hasWorksheet && isPresent(ExerciseUI.worksheet.customTabs)) {
      res += ExerciseUI.worksheet.customTabs.length;
    }
    if (ExerciseUI.hasWorksheet && isPresent(ExerciseUI.worksheet.downloadLinks)) {
      res += 1;
    }
    if (ExerciseUI.site.showWorksheetComments && ExerciseUI.hasWorksheet && ExerciseUI.worksheet.hasForumThread && !ExerciseUI.worksheet.forumThread.hidden && (ExerciseUI.worksheet.userHasAccess || ExerciseUI.worksheet.forumThread?.commentsCount > 0)) {
      res += 1;
    }
    return res;
  }

  @computed get minTabWidth() {
    if (AppUI.layout.viewportWidth <= 420) {
      if (this.tabCount === 2 || this.tabCount === 4) {
        return '40%';
      } return '30%';
    } return null;
  }

  @computed get tabSectionBorderRadius() {
    if (this.tabCount > 1 && !AppUI.layout.isMobile) {
      return "0 10px 10px 10px";
    }
    return this.standaloneSectionBorderRadius
  }

  @computed get standaloneSectionBorderRadius() {
    if (ExerciseUI.layout.isMobile) {
      return "0";
    }
    return "10px";
  }

  render() {
    return (
      <Wrapper id="worksheet-body">
        {
          this.showBackToTop && AppUI.routeStore.routeName === 'worksheets.vocab.show' &&
            <BackToTopButton
              floating
              background={AppUI.site.accentColor}
              left={AppUI.layout.isMobile ? null : min([AppUI.layout.viewportWidth - 50, this.worksheetRight - 40])}
              top={AppUI.layout.isMobile ? null : ExerciseUI.siteIsDefault ? min([AppUI.layout.viewportHeight - 100, this.worksheetBottom - 55]) : min([AppUI.layout.viewportHeight - 100, this.worksheetBottom - 61]) }
              right={AppUI.layout.isMobile ? '10px' : null}
              bottom={AppUI.layout.isMobile ? '50px' : null}
              onClick={this.goBackToTop}
            />
        }
        {
          this.showUserSettings &&
            <Modal
              top="200px"
              width={AppUI.layout.isMobile ? "80%" : "500px"}
              onCloseModal={() => this.showUserSettings = false}
              gradient={AppUI.siteIsDefault || isBlank(AppUI.site.accentColor)}
              background={!AppUI.siteIsDefault && isPresent(AppUI.site.accentColor) ? AppUI.site.accentColor : null}
              padding="0"
              title="Exercise Settings"
              contentShouldScroll
            >
              {
                (AppUI.site.language?.specialCharacters || AppUI.siteIsDefault) &&
                  <SettingsWrapper>
                    <ToggleWrapper
                      highlightColor={Theme.green}
                    >
                      <Toggle
                        icons={false}
                        onChange={() => this.toggleUserSetting("showSpecialCharacters")}
                        checked={ExerciseUI.user["showSpecialCharacters"]}
                      />
                      <Text
                        fontSize="12px"
                        bold
                        margin="0 0 0 10px"
                      >
                        Show Special Characters
                      </Text>
                    </ToggleWrapper>

                    <Note>
                      {`Show special characters specific to ${Languages[AppUI.site.languageId || 'DE']} (${isPresent(AppUI.site.language) ? AppUI.site.language.specialCharacters : 'äöüß'}) in writing exercises.`}
                    </Note>
                  </SettingsWrapper>
              }
              <SettingsWrapper>
                <ToggleWrapper
                  highlightColor={Theme.green}
                >
                  <Toggle
                    icons={false}
                    onChange={() => this.toggleUserSetting("hideEnglishPrompts")}
                    checked={ExerciseUI.user["hideEnglishPrompts"]}
                  />
                  <Text
                    fontSize="12px"
                    bold
                    margin="0 0 0 10px"
                  >
                    Hide English Prompts
                  </Text>
                </ToggleWrapper>
                <Note>
                  {`Hide the exercise prompts if they are an English translation of the ${Languages[AppUI.site.languageId || 'DE']} sentence.`}
                </Note>
              </SettingsWrapper>
            </Modal>
        }
        {
          ExerciseUI.layout.isDesktop &&
            <WorksheetInfo
              params={this.props.params}
              borderRadius={this.standaloneSectionBorderRadius}
            />
        }
        <Navigation
          id="page-top"
          flexDirection={AppUI.layout.isMobile ? "column" : "row"}
          alignItems={AppUI.layout.isMobile ? "flex-start" : "flex-end"}
        >
          {
            this.tabCount <= 1 &&
              <Tabs
                marginTop={AppUI.layout.isMobile ? "0px" : "32px"}
                marginBottom={AppUI.layout.isMobile ? "10px" : "0px"}
              />
          }
          {
            AppUI.layout.isMobile &&
              <WorksheetBackButton
                showAsBreadcrumbs
                namespace={ExerciseUI.worksheetNamespace}
                currentRouteName={last(this.props.router.routes).name}
              />
          }
          {
            this.tabCount > 1 &&
              <Tabs
                gap={AppUI.layout.isMobile ? "5px" : "10px"}
                marginTop={AppUI.layout.isMobile ? "0px" : "10px"}
                marginBottom={AppUI.layout.isMobile ? "10px" : "0px"}
              >
                {
                  ExerciseUI.worksheet?.hasPublishedVocab &&
                    <WorksheetTab
                      worksheetId={this.worksheetId}
                      params={this.props.params}
                      buttonText={'vocab'}
                      routeName={`${ExerciseUI.worksheetNamespace}.vocab.show`}
                      iconName='list'
                      minWidth={this.minTabWidth}
                    />
                }
                {
                  ExerciseUI.hasWorksheet && ExerciseUI.worksheet?.exercises.length > 0 &&
                    <WorksheetTab
                      worksheetId={this.worksheetId}
                      params={this.props.params}
                      buttonText={'exercises'}
                      routeName={`${ExerciseUI.worksheetNamespace}.exercises.show`}
                      iconName='lightbulb-o'
                      minWidth={this.minTabWidth}
                    />
                }
                {
                  ExerciseUI.hasWorksheet && isPresent(ExerciseUI.worksheet.customTabs) && ExerciseUI.worksheet.customTabs.map(item =>
                    {
                      return (
                        <WorksheetTab
                          key={item.id}
                          worksheetId={this.worksheetId}
                          customTabId={item.id}
                          params={this.props.params}
                          buttonText={item.title}
                          routeName={`${ExerciseUI.worksheetNamespace}.custom_tab.show`}
                          iconName={item.icon}
                          minWidth={this.minTabWidth}
                        />
                      )
                    }
                  )
                }
                {
                  ExerciseUI.hasWorksheet && isPresent(ExerciseUI.worksheet.downloadLinks) &&
                    <WorksheetTab
                      worksheetId={this.worksheetId}
                      params={this.props.params}
                      buttonText={'downloads'}
                      routeName={`${ExerciseUI.worksheetNamespace}.download_links.index`}
                      iconName='download'
                      minWidth={this.minTabWidth}
                    />
                }
                {
                  ExerciseUI.site.showWorksheetComments && ExerciseUI.hasWorksheet && ExerciseUI.worksheet.hasForumThread && !ExerciseUI.worksheet.forumThread.hidden && (ExerciseUI.worksheet.userHasAccess || ExerciseUI.worksheet.forumThread?.commentsCount > 0) &&
                    <WorksheetTab
                      worksheetId={this.worksheetId}
                      params={this.props.params}
                      buttonText={'discuss'}
                      routeName={`${ExerciseUI.worksheetNamespace}.comments.show`}
                      iconName='ChatOutline'
                      minWidth={this.minTabWidth}
                    />
                }
              </Tabs>
          }
          {
            ExerciseUI.layout.isDesktop  &&
              <IconsWrapper
                width={AppUI.layout.isMobile ? "100%" : null}
                justifyContent={AppUI.layout.isMobile ? "flex-end" : null}
                padding={AppUI.layout.isMobile ? "5px 10px 0 5px" : null}
              >
              {
                ExerciseUI.hasWorksheet && ExerciseUI.worksheet.hasPdf && ExerciseUI.worksheet.userHasAccess &&
                <a
                  className='underline'
                  href={isPresent(ExerciseUI.worksheet.pdfUrl) ? ExerciseUI.worksheet.pdfUrl : ExerciseUI.worksheet.pdf.url}
                  download='Worksheet'
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className='fa fa-print'/>
                </a>
              }
              {
                ExerciseUI.hasWorksheet && ExerciseUI.worksheet.hasPdf && !ExerciseUI.worksheet.userHasAccess &&
                <i className='fa fa-print not-allowed'/>
              }
              {
                ExerciseUI.hasWorksheet && ExerciseUI.worksheet?.exercises.length > 0 &&
                <i
                  className='fa fa-gear'
                  onClick={() => this.showUserSettings = !this.showUserSettings}
                />
              }
            </IconsWrapper>
          }
        </Navigation>
        {this.props.children && React.cloneElement(this.props.children, {
          borderRadius: this.tabSectionBorderRadius,
          standaloneSectionBorderRadius: this.standaloneSectionBorderRadius,
          tabCount: this.tabCount,
          onClickExerciseSettingsIcon: () => this.showUserSettings = !this.showUserSettings,
        })}
      </Wrapper>
    );
  }
}

export default WorksheetShow;
