import React from 'react';
import { observer } from 'mobx-react';
import { AiServiceStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';

@observer
class AiServiceCreate extends React.Component {

  onClick() {
    if (isPresent(this.refs.model.value) && isPresent(this.refs.client.value)) {
      AiServiceStore.create(
        {
          data: {
            model: this.refs.model.value,
            client: this.refs.client.value,
          },
        },
      );
      this.refs.model.value = '';
    }
  }

  render() {
    return (
      <div className="ai-service-create row">
        <div className="col-xs-10">
          <select ref="client">
            {this.props.clientOptions.map((item) => { return (<option key={item} value={item}>{item}</option>) })}
          </select>
          <input
            placeholder="Add a model"
            ref="model"
          />
        </div>
        <div className="col-xs-2">
          <button
            className="button-primary"
            onClick={this.onClick.bind(this)}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default AiServiceCreate;
