import styled from '@emotion/styled';
import autobind from 'autobind-decorator';
import Text from 'components/text';
import { observer } from 'mobx-react';
import WordStructureEdit from "pages/creator/worksheets/word_structure_edit";
import React from 'react';
import BackEndExplainer from '../back_end_explainer';
import InPlaceText from "components/form/in_place_text";
import { isPresent } from '@seedlang/utils';
import { observable } from 'mobx';
import Alert from 'components/alert';
import { WordStore } from "@seedlang/stores";

const Wrapper = styled.div`
`;

@observer
class WorksheetWordEdit extends React.Component {
  @observable showWarning = false;

  @autobind submit(originalText, newText, wordId) {
    var levenshtein = require('fast-levenshtein');
    var distance = levenshtein.get(originalText.toLowerCase(), newText.toLowerCase());
    
    if (distance <= 2) {
      this.updateTarget(newText, wordId);
    } else {
      this.showWarning = true;
      
    }
  }

  @autobind updateTarget(newText, wordId) {
    WordStore.update({ ids: { wordId: wordId }, data: { targetText: newText }}, this.props.afterChange);
  }

  render() {
    return (
      <Wrapper>
        <Text
          heading={3}
          margin="0 0 10px 0"
        >
          {`Edit \"${this.props.worksheetWord.word.targetText}\"`}
        </Text>
        <BackEndExplainer>
          <div>Please only edit the word to correct typos, in order to maintain consistency across the posts.</div>
          <div>If you need to change the word entirely, please remove it from the post and add the correct word.</div>
        </BackEndExplainer>
        {
          this.showWarning &&
            <Alert
              showCloseButton
              onClose={() => this.showWarning = false}
            >
              <div>The new word is too different from the original word.</div>
              <div>Please add a new word to the post instead of editing this one.</div>
            </Alert>
        }
        <table className="table-wrapper">
          <thead>
            <tr>
              <th width="50%">Target Text</th>
              <th width="50%">Plural</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {
                  isPresent(this.props.worksheetWord.word?.target) &&
                    <InPlaceText
                      submitOnEnter
                      id={this.props.worksheetWord.word?.target?.id}
                      model="targets"
                      field="text"
                      defaultValue={this.props.worksheetWord.word?.targetText}
                      onSubmit={(val, field) => this.submit(this.props.worksheetWord.word?.targetText, val, this.props.worksheetWord.word?.id)}
                    />
                  }
              </td>
              <td>
                {
                  isPresent(this.props.worksheetWord.word?.pluralNoun) &&
                    <InPlaceText
                      id={this.props.worksheetWord.word.pluralNoun.targetId}
                      model="targets"
                      field="text"
                      defaultValue={this.props.worksheetWord.word.pluralNoun.targetText}
                      onSubmit={(val, field) => this.submit(this.props.worksheetWord.word?.pluralNoun?.targetText, val, this.props.worksheetWord.word?.pluralNoun?.id)}
                    />
                }
              </td>
            </tr>
          </tbody>
        </table>
        {
          this.props.worksheetWord.word?.isVerb &&
            <WordStructureEdit
              worksheetWord={this.props.worksheetWord}
              afterChange={this.props.afterChange}
              languageId={this.props.worksheetWord.word?.languageId}
            />
        }
      </Wrapper>
    );
  }
}

export default WorksheetWordEdit;
