import React from 'react';
import { DeckUI, OverlayUI } from '@seedlang/state';
import styled from '@emotion/styled';
import { PencilIcon } from "@seedlang/icons";

const PencilIconWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  top: ${props => props.top};
  right: 5px;
  width: ${props => props.width};
  height: ${props => props.width};
  background: #FFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  .pencil-icon {
    height: 16px;
    margin: -2px -1px 0 0;
    svg {
      width: 16px;
      fill: #333;
    }
`;

class SourceEditIcon extends React.Component {
  static defaultProps = {
    top: "5px",
  }

  render() {
    return (
      <PencilIconWrapper
        width={DeckUI.layout.minimumHeight ? "30px" : "40px"}
        className='pencil-icon-wrapper'
        onClick={() => OverlayUI.addOverlay('sourceEdit')}
        top={this.props.top}
      >
        <PencilIcon />
      </PencilIconWrapper>
    );
  }
}

export default SourceEditIcon;
