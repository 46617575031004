import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import styled from '@emotion/styled';
import InPlaceSelect from 'components/form/in_place_select';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceText from 'components/form/in_place_text';
import Text from 'components/text';
import Button from 'components/button/button';
import { Theme, Constants } from '@seedlang/constants';
import Alert from 'components/alert';
import { isBlank } from '@seedlang/utils';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
`;

const ButtonText = styled.div`
  width: 280px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.color};
  .fa-arrow-right {
    transform: rotate(-45deg);
  }
  i {
    position: absolute;
    left: -5px;
    top: 16px;
    color: ${props => props.color};
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CreateNewReviewDeck = styled.div`
`;

const HideMessage = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #CCC;
  i {
    margin-right: 5px;
  }
`;

@observer
class ReviewToggleOptions extends React.Component {

  @computed get buttonBackground() {
    if (!this.props.enableUpdateReviews) {
      return "#CCC";
    }
    if (AppUI.siteIsDefault) {
      return Theme.blue;
    }
    return AppUI.site.accentColor;
  }

  render () {
    return (
      <Wrapper>
        {
          this.props.message &&
            <Alert
              background={Theme.green}
              width="100%"
              textAlign="center"
              margin="0 0 10px 0"
            >
              {this.props.message}
            </Alert>
        }
        {
          isBlank(this.props.selectedCardTypeIds) &&
            <Alert
              width="100%"
              textAlign="center"
              background={Theme.green}
              margin="0 0 10px 0"
            >
              <i className="fa fa-exclamation-triangle" style={{margin: "3px 3px 0 0"}} />
              You must select a Card Type
            </Alert>
        }
        {
          this.props.reviewDeckOptions?.length > 1 &&
            <Text
              fontSize="12px"
              bold
              margin="10px 0 0 0"
              color="#333"
            >
              {AppUI.siteIsDefault ? "Review Deck" : "Seedlang Review Deck"}
            </Text>
        }
        {
          this.props.reviewDeckOptions?.length > 1 &&
            <InPlaceSelect
              allowUpdate
              height="45px"
              options={this.props.reviewDeckOptions}
              value={this.props.deckId}
              onChange={(val) => this.props.onSetDeckId(val)}
            />
        }
        <Text
          cursor="pointer"
          underline
          fontSize="12px"
          color="#333"
          margin="3px 0 0 0"
          onClick={this.props.toggleShowCreateNewReviewDeck}
        >
          Create New Review Deck
        </Text>
        {
          this.props.showCreateNewReviewDeck &&
            <CreateNewReviewDeck>
              <Text
                fontSize="12px"
                bold
                margin="10px 0 0 0"
                color="#333"
              >
                Review Deck Name
              </Text>
              <InPlaceText
                allowUpdate
                value={this.props.reviewDeckName}
                onChange={(val) => this.props.onSetReviewDeckName(val)}
                show
                inputHeight="45px"
              />
              <Button
                height="50px"
                width="100%"
                margin="20px 0 20px 0"
                onClick={this.props.onCreateReviewDeck}
                background={this.props.enableCreateReviewDeck ? Theme.blue : "#CCC"}
                cursor={this.props.enableCreateReviewDeck ? "pointer" : "default"}
              >
                <ButtonText
                  color="#FFF"
                >
                  <i className='fa fa-plus' />
                  Create Review Deck
                </ButtonText>
              </Button>
            </CreateNewReviewDeck>
        }
        {
          !this.props.card &&
            <Text
              fontSize="12px"
              bold
              margin="10px 0 0 0"
              color="#333"
            >
              Card Type
            </Text>
        }
        {
          (!this.props.card || this.props.showOptions) && this.props.cardTypeOptions.map(item => {
            const hideFromGenderTrainer = Constants.REVIEW_CARD_TYPES.find(i => i.id === item[0]).slug === "gender" && this.props.word.hideFromGenderTrainer;
            const hideFromPluralTrainer = Constants.REVIEW_CARD_TYPES.find(i => i.id === item[0]).slug === "plural" && (this.props.word.hideFromPluralTrainer || this.props.word.noPlural);
            const hideFromConjugationTrainer = Constants.REVIEW_CARD_TYPES.find(i => i.id === item[0]).slug === "conjugation" && (this.props.word.hideFromConjugationTrainer);
            return (
              <CheckboxWrapper
                key={item[0]}
              >
                {
                  hideFromGenderTrainer &&
                    <HideMessage>
                      <i className='fa fa-check' /> Gender (Disabled)
                    </HideMessage>

                }
                {
                  hideFromPluralTrainer &&
                    <HideMessage>
                      <i className='fa fa-check' /> Plural (Disabled)
                    </HideMessage>

                }
                  {
                    hideFromConjugationTrainer &&
                      <HideMessage>
                          <i className='fa fa-check' /> Conjugation (Disabled)
                      </HideMessage>

                  }
                {
                  !hideFromGenderTrainer && !hideFromPluralTrainer && !hideFromConjugationTrainer &&
                    <InPlaceCheckbox
                      allowUpdate
                      value={this.props.selectedCardTypeIds.indexOf(item[0]) !== -1}
                      onChange={() => this.props.onToggleCardTypeId(item[0])}
                      icon="check"
                    >
                      <Text
                        color="#333"
                        bold
                      >
                        {item[1]}
                      </Text>
                    </InPlaceCheckbox>
                }
              </CheckboxWrapper>
            )
          })
        }
        <Button
          height="50px"
          width="100%"
          margin="20px 0 0 0"
          onClick={this.props.onSubmit}
          background={this.buttonBackground}
          cursor={this.props.enableUpdateReviews ? "pointer" : "default"}
        >
          <ButtonText
            color="#FFF"
          >
            <i className='fa fa-plus' />
            {this.props.hasReviews ? 'Update Review' : 'Create Review'}
          </ButtonText>
        </Button>
      </Wrapper>
    );
  }
}

export default ReviewToggleOptions;
