import React from 'react';
import { observer } from 'mobx-react';
import styled from "@emotion/styled";

const Wrapper = styled.div`
  border-radius: 10px;
  background: ${props => props.background};
  align-items: center;
  padding: ${props => props.compact ? '3px' : '10px 20px 10px 10px'};
  margin-bottom: ${props => props.compact ? '0' : '10px'};
  margin-top: ${props => props.marginTop};
  font-size: ${props => props.compact ? '12px' : '16px'};
  line-height: ${props => props.compact ? '1.2' : '1.5'};
  display: flex;
  i {
    margin: ${props => props.compact ? '0px 10px 0px 10px' : '0px 10px 5px 5px'};
  }
`;

@observer
class BackEndExplainer extends React.Component {

  static defaultProps = {
    background: "#ffffffad",
    marginTop: '10px'
  }

  render() {
    return (
      <Wrapper
        background={this.props.background}
        compact={this.props.compact}
        marginTop={this.props.marginTop}
      >
        <i className='fa fa-lightbulb-o' />
        <div>{this.props.children}</div>
      </Wrapper>
    )
  }
}

export default BackEndExplainer;
