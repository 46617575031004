import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import Confirmation from "../confirmation";
import ReactTooltip from 'react-tooltip';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  margin: ${props => props.margin};
  width: fit-content;
`;

@observer
class DeleteButton extends React.Component {
  @observable showConfirmation = false;

  constructor (props) {
    super();
    this.id = Math.random();
  }

  static defaultProps = {
    right: "40px",
    left: "auto",
    top: "-30px",
    faIcon: "fa-times",
  }

  @autobind onConfirm() {
    this.showConfirmation = false;
    if ((AppUI.user.currentUserGroup?.canDeleteRecords || this.props.allowDeletion) && this.props.onConfirm) {
      this.props.onConfirm();
    }
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
        data-tip
        data-for={`confirmation-tooltip-${this.id}`}
        disabled={this.props.disabled}
      >
        {
          this.showConfirmation &&
            <Confirmation
              left={this.props.left}
              right={this.props.right}
              top={this.props.top}
              floatsRight={this.props.confirmationMessageFloatsRight}
              close={() => this.showConfirmation = false}
              onConfirm={this.onConfirm}
              showCloseButton={!AppUI.user.currentUserGroup?.canDeleteRecords && !this.props.allowDeletion}
              message={AppUI.user.currentUserGroup?.canDeleteRecords || this.props.allowDeletion ? this.props.message : "⚠ You don't have deletion rights."}
            />
        }
        {
          this.props.children &&
            <div
              onClick={() => this.props.disabled ? null : this.showConfirmation = true}
              className='delete-button-wrapper'
            >
              {this.props.children}
            </div>
        }
        {
          !this.props.children &&
            <i
              onClick={() => this.props.disabled ? null : this.showConfirmation = true}
              className={`fa ${this.props.faIcon} ${this.props.disabled ? '' : 'fa-on-click '}${this.props.className}`}
              style={{color: this.props.iconColor, opacity: this.props.disabled ? '0.6' : '1'}}
            />
        }
        {
          this.props.message && this.props.showTooltip &&
            <ReactTooltip
              place="left"
              type="dark"
              effect="solid"
              id={`confirmation-tooltip-${this.id}`}
              class="custom-tooltip"
            >
              {this.props.message}
            </ReactTooltip>
        }
      </Wrapper>
    );
  }
}

export default DeleteButton;
