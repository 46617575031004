import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import {
  CustomTabStore,
  DownloadLinkTypeStore,
  GroupStore,
  MembershipGroupStore,
  PodcastStore,
  SiteStore,
  ContactCategoryStore
} from '@seedlang/stores';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceText from 'components/form/in_place_text';
import InPlaceImageUpload from 'components/form/in_place_image_upload';
import autobind from 'autobind-decorator';
import NavigationItemIndex from 'pages/builder/navigation_items/navigation_item_index';
import Cookies from 'js-cookie';
import Copy from 'components/copy';
import { isBlank } from "@seedlang/utils";
import Text from 'components/text';
import RadioButton from "components/radio_button";
import { Link } from "react-router";
import DeleteButton from "components/button/delete_button";
import InfoTooltip from "components/info_tooltip";

const Wrapper = styled.div`
  .small {
    font-size: 12px;
    line-height: 14px!important;
  }
`;

const ColorRow = styled.div`
  display: flex;
  align-items: center;
  .fa {
    margin-right: 5px;
    color: ${props => props.color}
  }
`;

const Title = styled.div`
  background: #FFF;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  margin: ${props => props.margin || '20px 0 10px 0'};
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-top: 3px!important;
`;

const iconOptions = [
  ['file-pdf-o', 'pdf'],
  ['volume-up', 'sound'],
  ['film', 'video'],
  ['link', 'link'],
  ['download', 'download'],
  ['image', 'image'],
  ['archive', 'archive'],
];

@observer
class SiteEdit extends React.Component {
  @observable requireCorrectCase;
  @observable requireCorrectSpecialCharacters;

  static defaultProps = {
    whiteLabeled: false
  }

  @observable showIconSelector = {
    downloadLinkIcon1: false,
    downloadLinkIcon2: false,
    downloadLinkIcon3: false,
    downloadLinkIcon4: false,
    downloadLinkIcon5: false,
    downloadLinkIcon6: false,
  }

  constructor(props) {
    super(props);
    this.getSite();
    if (!MembershipGroupStore.hasIndexData) {
      MembershipGroupStore.getIndex();
    }
    if (!DownloadLinkTypeStore.hasIndexData) {
      DownloadLinkTypeStore.getIndex({});
    }
    if (!PodcastStore.hasIndexData) {
      PodcastStore.getIndex({});
    }
    if (!CustomTabStore.hasIndexData) {
      CustomTabStore.getIndex({});
    }
    if (!ContactCategoryStore.hasIndexData) {
      ContactCategoryStore.getIndex();
    }
  }

  componentDidMount() {
    this.getSite();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.routeParams.conceptId !== prevProps.routeParams.conceptId) {
      this.getSite();
    }
  }

  componentWillUnmount() {
    SiteStore.clearShowData();
  }

  @computed get site() {
    return SiteStore.showData;
  }

  @autobind getSite() {
    SiteStore.getShow({ ids: { siteId: this.props.whiteLabeled ? Cookies.get('site_id') : this.props.routeParams.siteId }}, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    this.requireCorrectCase = resp.requireCorrectCase;
    this.requireCorrectSpecialCharacters = resp.requireCorrectSpecialCharacters;
    SiteStore.setShowData(resp);
    [1,2,3,4,5,6].map(num => {
      return this.showIconSelector[`downloadLinkIcon${num}`] = isBlank(resp[`downloadLinkIcon${num}`]);
    });
  }

  @autobind loadGroup() {
    GroupStore.getShow({ids: {groupId: this.props.params.groupId}});
  }

  @autobind toggleRequireCorrectCase() {
    const newValue = !this.requireCorrectCase;
    this.requireCorrectCase = newValue;
    SiteStore.update({data: {require_correct_case: newValue}, ids: {siteId: SiteStore.showData.id}}, this.getSite)
  }

  @autobind toggleRequireCorrectSpecialCharacters() {
    const newValue = !this.requireCorrectSpecialCharacters;
    this.requireCorrectSpecialCharacters = newValue;
    SiteStore.update({data: {require_correct_special_characters: newValue}, ids: {siteId: SiteStore.showData.id}}, this.getSite)
  }

  @autobind deleteContactCategory(id) {
    ContactCategoryStore.destroy({ids: {contactCategoryId: id}});
  }

  @autobind createContactCategory() {
    ContactCategoryStore.create({data: {site_id: this.site.id}});
  }

  render() {
    return (
      <Wrapper>
        {
          SiteStore.hasShowData &&
            <>
              <Title
                margin="0 0 20px 0"
              >
                General
              </Title>
              <div className="row">
                <div className="col-xs-3">
                  <fieldset>
                    <legend>Name</legend>
                    <InPlaceText
                      id={this.site.id}
                      defaultValue={this.site.name}
                      model="sites"
                      field="name"
                    />
                  </fieldset>
                  {
                    !SiteStore.showData.isDefault &&
                      <fieldset>
                        <legend>Team Name</legend>
                        <InPlaceText
                          id={this.site.id}
                          defaultValue={this.site.teamName}
                          model="sites"
                          field="team_name"
                        />
                      </fieldset>
                  }
                </div>
                {
                  SiteStore.showData.isDefault &&
                    <div className="col-xs-3">
                      <fieldset>
                        <legend>Feature Flags</legend>
                        <div>
                          <InPlaceCheckbox
                            id={this.site.id}
                            value={this.site.showVideoTimeoutMessage}
                            model="sites"
                            field="show_video_timeout_message"
                            icon="check"
                          >
                            Show Video Timeout Message
                          </InPlaceCheckbox>
                        </div>
                      </fieldset>
                    </div>

                }
                {
                  !SiteStore.showData.isDefault &&
                    <div className="col-xs-3">
                      <fieldset>
                        <legend>Language ID</legend>
                        <InPlaceText
                          id={this.site.id}
                          defaultValue={this.site.languageId}
                          model="sites"
                          field="language_id"
                        />
                      </fieldset>
                    </div>
                }
                <div className="col-xs-3">
                  <fieldset>
                    <legend>Site Attributes</legend>
                    <div>
                      <InPlaceCheckbox
                        id={this.site.id}
                        value={this.site.published}
                        model="sites"
                        field="published"
                        icon="check"
                      >
                        Published
                      </InPlaceCheckbox>
                    </div>
                    <div>
                      <InPlaceCheckbox
                        id={this.site.id}
                        value={this.site.paymentTestMode}
                        model="sites"
                        field="payment_test_mode"
                        icon="check"
                      >
                        Payment Test Mode
                      </InPlaceCheckbox>
                    </div>
                    <div>
                      <InPlaceCheckbox
                        id={this.site.id}
                        value={this.site.allowPatreonSignIn}
                        model="sites"
                        field="allow_patreon_sign_in"
                        icon="check"
                      >
                        Sign in with Patreon
                      </InPlaceCheckbox>
                    </div>
                    <div>
                      <InPlaceCheckbox
                        id={this.site.id}
                        value={this.site.useGridLayout}
                        model="sites"
                        field="use_grid_layout"
                        icon="check"
                      >
                        Show Grid Sections
                      </InPlaceCheckbox>
                    </div>
                    <div>
                      <InPlaceCheckbox
                        id={this.site.id}
                        value={this.site.showCustomMembershipHtml}
                        model="sites"
                        field="show_custom_membership_html"
                        icon="check"
                      >
                        Show Membership HTML
                      </InPlaceCheckbox>
                    </div>
                    <div>
                      <InPlaceCheckbox
                        id={this.site.id}
                        value={this.site.hasPodcast}
                        model="sites"
                        field="has_podcast"
                        icon="check"
                        afterChange={this.getSite}
                      >
                        Has Podcast
                      </InPlaceCheckbox>
                    </div>
                  </fieldset>
                </div>
                <div className='col-xs-3'>
                  <fieldset>
                    <legend>Post Attributes</legend>
                    <div>
                      <InPlaceCheckbox
                        id={this.site.id}
                        value={this.site.blockPostNotifications}
                        model="sites"
                        field="block_post_notifications"
                        icon="check"
                      >
                        Block Notifications
                        <InfoTooltip>
                          <div>This will block in-site notifications and emails.</div>
                          <div>This option can be useful while setting up the back catalogue.</div>
                          <div>Blocking post notifications can also be done on a post by post basis.</div>
                        </InfoTooltip>
                      </InPlaceCheckbox>
                    </div>
                    <div>
                      <InPlaceCheckbox
                        id={this.site.id}
                        value={this.site.showWorksheetFilter}
                        model="sites"
                        field="show_worksheet_filter"
                        icon="check"
                      >
                        Show Posts Filter
                      </InPlaceCheckbox>
                    </div>
                    <div>
                      <InPlaceCheckbox
                        id={this.site.id}
                        value={this.site.showWorksheetComments}
                        model="sites"
                        field="show_worksheet_comments"
                        icon="check"
                      >
                        Show Post Comments
                      </InPlaceCheckbox>
                    </div>
                  </fieldset>
                </div>
                {
                  this.site.isDefault &&
                  <div className='row'>
                    <div className="col-xs-3">
                      <fieldset>
                        <legend>Web Version</legend>
                        <InPlaceText
                          id={this.site.id}
                          defaultValue={this.site.currentWebVersion}
                          model="sites"
                          field="current_web_version"
                        />
                      </fieldset>
                    </div>
                    <div className="col-xs-3">
                      <fieldset>
                        <legend>iOS Version</legend>
                        <InPlaceText
                          id={this.site.id}
                          defaultValue={this.site.currentIosVersion}
                          model="sites"
                          field="current_ios_version"
                        />
                        <div className='small'>
                          Only set this after it's been released for a week.
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-xs-3">
                      <fieldset>
                        <legend>Android Version</legend>
                        <InPlaceText
                          id={this.site.id}
                          defaultValue={this.site.currentAndroidVersion}
                          model="sites"
                          field="current_android_version"
                        />
                        <div className='small'>
                          Only set this after it's been released for a week.
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-xs-3">
                      <fieldset>
                        <legend>Show Maintenance Page</legend>
                        <InPlaceCheckbox
                          id={this.site.id}
                          value={this.site.showMaintenancePage}
                          model="sites"
                          field="show_maintenance_page"
                          icon="check"
                        />
                        <div>
                          <div className='small'>
                            This will show the maintenance page to all non-admins.
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-xs-3">
                      <fieldset>
                        <legend>Last Trivia Published At</legend>
                        {this.site.daysSinceLastTriviaUpdate} Days Ago
                      </fieldset>
                    </div>
                  </div>
                }
              </div>
              {
                !this.siteIsDefault &&
                <>
                  <div className='row'>
                    <div className="col-xs-6">
                      <fieldset>
                        <legend>Terms of Service URL</legend>
                        <InPlaceText
                          id={this.site.id}
                          defaultValue={this.site.termsOfServiceUrl}
                          model="sites"
                          field="terms_of_service_url"
                        />
                      </fieldset>
                    </div>
                    <div className="col-xs-6">
                      <fieldset>
                        <legend>Privacy URL</legend>
                        <InPlaceText
                          id={this.site.id}
                          defaultValue={this.site.privacyUrl}
                          model="sites"
                          field="privacy_url"
                        />
                      </fieldset>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-xs-12">
                      <fieldset>
                        <legend>Privacy Modal Text</legend>
                        <InPlaceText
                          richText
                          inputType="textarea"
                          id={this.site.id}
                          defaultValue={this.site.privacyTextHtml}
                          model="sites"
                          field="privacy_text_html"
                          maxHeight="30em"
                          scrollable
                        />
                      </fieldset>
                    </div>
                  </div>
                </>
              }
              <Title
              >
                Patreon
              </Title>
              <div className='row'>
                <div className='col-xs-6'>
                  <fieldset>
                    <legend>Patreon Campaign ID</legend>
                    <InPlaceText
                      defaultValue={GroupStore.showData.patreonCampaignId}
                      model="groups"
                      field="patreon_campaign_id"
                      id={GroupStore.showData.id}
                    />
                  </fieldset>
                </div>
                <div className='col-xs-3'>
                  <fieldset>
                    <legend>Invite Link</legend>
                    <Copy
                      text={`https://seedlang.com?assign_group_id=${this.props.params.groupId}`}
                    />
                  </fieldset>
                </div>
                <div className='col-xs-3'>
                  <fieldset>
                    <legend>Show Tags</legend>
                    <InPlaceCheckbox
                      value={GroupStore.showData.showTags}
                      model="groups"
                      field="show_tags"
                      id={GroupStore.showData.id}
                      icon="check"
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Patreon Client ID</legend>
                    <InPlaceText
                      defaultValue={GroupStore.showData.patreonClientId}
                      model="groups"
                      field="patreon_client_id"
                      id={GroupStore.showData.id}
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Patreon Client Secret</legend>
                    <InPlaceText
                      defaultValue={GroupStore.showData.patreonClientSecret}
                      model="groups"
                      field="patreon_client_secret"
                      id={GroupStore.showData.id}
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Creator Access Token</legend>
                    <InPlaceText
                      defaultValue={GroupStore.showData.patreonCreatorAccessToken}
                      model="groups"
                      field="patreon_creator_access_token"
                      id={GroupStore.showData.id}
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Creator Refresh Token</legend>
                    <InPlaceText
                      defaultValue={GroupStore.showData.patreonCreatorRefreshToken}
                      model="groups"
                      field="patreon_creator_refresh_token"
                      id={GroupStore.showData.id}
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Webhook Secret</legend>
                    <InPlaceText
                      defaultValue={GroupStore.showData.patreonWebhookSecret}
                      model="groups"
                      field="patreon_webhook_secret"
                      id={GroupStore.showData.id}
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-6'>
                  <fieldset>
                    <legend>All Memberships Have Access</legend>
                    <InPlaceCheckbox
                      value={GroupStore.showData.allowAllMemberships}
                      model="groups"
                      icon="check"
                      field="allow_all_memberships"
                      id={GroupStore.showData.id}
                      afterChange={this.loadGroup}
                    />
                  </fieldset>
                </div>
                {
                  !GroupStore.showData.allowAllMemberships &&
                    <div className='col-xs-6'>
                      <fieldset>
                        <legend>Memberships w/ Access</legend>
                        <InPlaceText
                          defaultValue={GroupStore.showData.allowedMemberships}
                          model="groups"
                          field="allowed_memberships"
                          id={GroupStore.showData.id}
                        />
                        <div style={{margin: "5px 0 0 0"}}>
                          <div className='small'>Separate each item with a comma: a,b,c</div>
                        </div>
                      </fieldset>
                    </div>
                }
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>No-Access Text</legend>
                    <InPlaceText
                      richText
                      defaultValue={GroupStore.showData.noAccessText}
                      model="groups"
                      field="no_access_text"
                      id={GroupStore.showData.id}
                    />
                  </fieldset>
                </div>
              </div>
              {
                !this.site.published &&
                  <div className='row'>
                    <div className='col-xs-12'>
                      <fieldset>
                        <legend>Patreon Membership Page</legend>
                        <InPlaceText
                          defaultValue={this.site.patreonMembershipPage}
                          model="sites"
                          field="patreon_membership_page"
                          id={this.site.id}
                        />
                      </fieldset>
                    </div>
                    <div className='small'>A link to your patreon membership will show up while the site is unpublished</div>
                  </div>
              }
              <Title>
                Payment Settings
              </Title>
              <div className='row'>
                <div className='col-xs-3'>
                  <fieldset>
                    <legend>Allow Promotion Codes</legend>
                    <InPlaceCheckbox
                      model="sites"
                      field="allow_promotion_codes"
                      value={this.site.allowPromotionCodes}
                      id={this.site.id}
                      icon="check"
                    />
                  </fieldset>
                </div>
              </div>
              <Title>
                Payment Migration Settings
              </Title>
                <Text fontSize='14px' margin='5px 0'>Select the content you want to block for members who haven't started their migration:</Text>
              <div className='row'>
                <div className='col-xs-3'>
                  <fieldset style={{height: '100%'}}>
                    <legend>Vocab and Exercises</legend>
                    <div>
                      <InPlaceCheckbox
                        model="sites"
                        field="block_vocab_if_not_migrated"
                        value={this.site.blockVocabIfNotMigrated}
                        id={this.site.id}
                        icon="lock"
                        afterChange={this.getSite}
                        display="flex"
                      >
                        Vocabulary tab
                      </InPlaceCheckbox>
                    </div>
                    <div>
                      <InPlaceCheckbox
                        model="sites"
                        field="block_exercises_if_not_migrated"
                        value={this.site.blockExercisesIfNotMigrated}
                        id={this.site.id}
                        icon="lock"
                        afterChange={this.getSite}
                        display="flex"
                      >
                        Exercises tab
                      </InPlaceCheckbox>
                    </div>
                  </fieldset>
                </div>
                <div className='col-xs-3'>
                  <fieldset style={{height: '100%'}}>
                    <legend>Download Link Types</legend>
                    {
                      DownloadLinkTypeStore.indexData.map(item =>
                        <div>
                          <InPlaceCheckbox
                            model="download_link_types"
                            field="block_if_not_migrated"
                            value={item.blockIfNotMigrated}
                            id={item.id}
                            icon="lock"
                            display="flex"
                          >
                            {item.title}{item.subtitle ? ` - ${item.subtitle}` : ''}
                          </InPlaceCheckbox>
                        </div>
                      )
                    }
                  </fieldset>
                </div>
                <div className='col-xs-3'>
                  <fieldset style={{height: '100%'}}>
                    <legend>Podcasts</legend>
                    {
                      PodcastStore.indexData.map(item =>
                        <div>
                          <InPlaceCheckbox
                            model="podcasts"
                            field="block_if_not_migrated"
                            value={item.blockIfNotMigrated}
                            id={item.id}
                            icon="lock"
                            display="flex"
                          >
                            {item.title}
                          </InPlaceCheckbox>
                        </div>
                      )
                    }
                  </fieldset>
                </div>
                <div className='col-xs-3'>
                  <fieldset style={{height: '100%'}}>
                    <legend>Custom Tabs</legend>
                    {
                      CustomTabStore.indexData.map(item =>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                          <InPlaceCheckbox
                            model="podcasts"
                            field="block_if_not_migrated"
                            value={item.blockIfNotMigrated}
                            id={item.id}
                            icon="lock"
                            display="flex"
                          >
                            {item.title}
                          </InPlaceCheckbox>
                          <Link
                            to={{ name: 'creator.groups.custom_tabs.edit', params: { customTabId: item.id, groupId: this.props.params.groupId } }}
                            className="on-click"
                            target='_blank'
                          >
                            <i className='fa fa-chevron-right fa-block' />
                          </Link>
                        </div>
                      )
                    }
                  </fieldset>
                </div>
              </div>
              <Title
              >
                Social Media
              </Title>
              <div className='row'>
                <div className="col-xs-4">
                  <fieldset>
                    <legend>Youtube URL</legend>
                    <InPlaceText
                      id={this.site.id}
                      defaultValue={this.site.youtubeUrl}
                      model="sites"
                      field="youtube_url"
                    />
                  </fieldset>
                </div>
                <div className="col-xs-4">
                  <fieldset>
                    <legend>Instagram URL</legend>
                    <InPlaceText
                      id={this.site.id}
                      defaultValue={this.site.instagramUrl}
                      model="sites"
                      field="instagram_url"
                    />
                  </fieldset>
                </div>
                <div className="col-xs-4">
                  <fieldset>
                    <legend>TikTok URL</legend>
                    <InPlaceText
                      id={this.site.id}
                      defaultValue={this.site.tikTokUrl}
                      model="sites"
                      field="tik_tok_url"
                    />
                  </fieldset>
                </div>
              </div>
              <Title
              >
                Discord
              </Title>
              <div className='row'>
                <div className='col-xs-6'>
                  <fieldset>
                    <legend>Discord Server Id</legend>
                    <InPlaceText
                      defaultValue={this.site.discordServerId}
                      model="sites"
                      field="discord_server_id"
                      id={this.site.id}
                    />
                  </fieldset>
                </div>
              </div>
              <Title>
                Routing
              </Title>
              <div className='row'>
                <div className="col-xs-3">
                  <fieldset>
                    <legend>Domain</legend>
                    <InPlaceText
                      id={this.site.id}
                      defaultValue={this.site.domainString}
                      model="sites"
                      field="domain_string"
                    />
                  </fieldset>
                </div>
                <div className="col-xs-3">
                  <fieldset>
                    <legend>Signed In Home Page</legend>
                    <InPlaceText
                      id={this.site.id}
                      defaultValue={this.site.signedInHomePageRoute}
                      model="sites"
                      field="signed_in_home_page_route"
                    />
                  </fieldset>
                </div>
                <div className="col-xs-3">
                  <fieldset>
                    <legend>Signed Out Home Page</legend>
                    <InPlaceText
                      id={this.site.id}
                      defaultValue={this.site.signedOutHomePageRoute}
                      model="sites"
                      field="signed_out_home_page_route"
                    />
                  </fieldset>
                </div>
              </div>
              <Title>
                Styling
              </Title>
              <div className='row'>
                <div className="col-xs-6">
                  <fieldset>
                    <legend>Main Team Color</legend>
                    <ColorRow
                      color={this.site.accentColor}
                    >
                      {
                        this.site.accentColor &&
                        <i className='fa fa-square' />
                      }
                      <InPlaceText
                        submitOnEnter
                        id={this.site.id}
                        defaultValue={this.site.accentColor}
                        model="sites"
                        field='accent_color'
                        afterChange={this.getSite}
                      />
                    </ColorRow>
                    <div className='small'>
                      This is the color used in your logo.
                      On the platform, it will be used in action buttons with white text and icons on it.
                    </div>
                  </fieldset>
                </div>
                <div className="col-xs-6">
                  <fieldset>
                    <legend>Secondary Team Color</legend>
                    <ColorRow
                      color={this.site.secondaryAccentColor}
                    >
                      {
                        this.site.secondaryAccentColor &&
                        <i className='fa fa-square' />
                      }
                      <InPlaceText
                        submitOnEnter
                        id={this.site.id}
                        defaultValue={this.site.secondaryAccentColor}
                        model="sites"
                        field='secondary_accent_color'
                        afterChange={this.getSite}
                      />
                    </ColorRow>
                    <div className='small'>
                      A color that works well in conjunction with the main team color.
                      It will be used as the background for white text and as the color for links.
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className="col-xs-6">
                  <fieldset>
                    <legend>Neutral Dark Color</legend>
                    <ColorRow
                      color={this.site.footerBackgroundColor}
                    >
                      {
                        this.site.footerBackgroundColor &&
                        <i className='fa fa-square' />
                      }
                      <InPlaceText
                        submitOnEnter
                        id={this.site.id}
                        defaultValue={this.site.footerBackgroundColor}
                        model="sites"
                        field='footer_background_color'
                        afterChange={this.getSite}
                      />
                    </ColorRow>
                    <div className='small'>
                      A dark color used in the footer and in the background of the sign in page.
                    </div>
                  </fieldset>
                </div>
              </div>
              {
                !this.props.whiteLabeled &&
                  <div className="row">
                    <div className="col-xs-12">
                      <fieldset>
                        <legend>Navigation</legend>
                        <NavigationItemIndex
                          items={this.site.navigationItems}
                          onItemDelete={this.onNavItemDelete}
                        />
                      </fieldset>
                    </div>
                  </div>
              }
              <div className="row">
                <div className="col-xs-12">
                  <fieldset>
                    <legend>Before Memberships Listing HTML</legend>
                    <InPlaceText
                      wrapperClassName="word-info"
                      richText
                      defaultValue={this.site.beforeMembershipsListingHtml}
                      model="sites"
                      field="before_memberships_listing_html"
                      inputType="textarea"
                      id={this.site.id}
                    />
                  </fieldset>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <fieldset>
                    <legend>After Memberships Listing HTML</legend>
                    <InPlaceText
                      wrapperClassName="word-info"
                      richText
                      defaultValue={this.site.afterMembershipsListingHtml}
                      model="sites"
                      field="after_memberships_listing_html"
                      inputType="textarea"
                      id={this.site.id}
                    />
                  </fieldset>
                </div>
              </div>
              <Title>Metadata</Title>
              <div className="row">
                <div className="col-xs-12">
                  <fieldset>
                    <legend>Title</legend>
                    <InPlaceText
                      id={this.site.id}
                      defaultValue={this.site.title}
                      model="sites"
                      field="title"
                    />
                  </fieldset>
                </div>
                <div className="col-xs-12">
                  <fieldset>
                    <legend>Description</legend>
                    <InPlaceText
                      wrapperClassName="word-info"
                      defaultValue={this.site.description}
                      model="sites"
                      field="description"
                      inputType="textarea"
                      id={this.site.id}
                    />
                  </fieldset>
                </div>
                <div className="col-xs-6">
                  <fieldset>
                    <legend>Facebook App ID</legend>
                    <InPlaceText
                      id={this.site.id}
                      defaultValue={this.site.fbAppId}
                      model="sites"
                      field="fb_app_id"
                    />
                  </fieldset>
                </div>
              </div>
              <Title>Images</Title>
              <div className="row">
                <div className="col-xs-6">
                  <fieldset>
                    <legend>Navigation Logo</legend>
                    <InPlaceImageUpload
                      image={this.site.image}
                      model="images"
                      onChange={this.getSite}
                      querystring={{
                        imageable_id: this.site.id,
                        imageable_type: 'Site',
                      }}
                    />
                    <div className='small'>This will appear in the top left of the site navigation.</div>
                  </fieldset>
                </div>
                <div className="col-xs-6">
                  <fieldset>
                    <legend>Team Logo</legend>
                    <InPlaceImageUpload
                      image={this.site.teamLogo && this.site.teamLogo.image}
                      model="team_logos"
                      id={this.site.teamLogo?.id}
                      onChange={this.getSite}
                      querystring={{
                        site_id: this.site.id
                      }}
                    />
                    <div className='small'>This will appear in places such as the email footer and the notifications screen. It should not have any text in the image other than the team name and should have a transparent background.</div>
                  </fieldset>
                </div>
                <div className="col-xs-6">
                  <fieldset>
                    <legend>Favicon</legend>
                    <InPlaceImageUpload
                      circle
                      image={this.site.favicon && this.site.favicon.image}
                      model="favicons"
                      id={this.site.favicon?.id}
                      onChange={this.getSite}
                      querystring={{
                        site_id: this.site.id
                      }}
                    />
                  </fieldset>
                </div>
                <div className="col-xs-6">
                  <fieldset>
                    <legend>Social Image</legend>
                    <InPlaceImageUpload
                      image={this.site.socialImage && this.site.socialImage.image}
                      model="social_images"
                      id={this.site.socialImage?.id}
                      onChange={this.getSite}
                      querystring={{
                        site_id: this.site.id
                      }}
                    />
                  </fieldset>
                </div>
              </div>
              <Title>Email</Title>
              <div className='row'>
                <div className="col-xs-4">
                  <fieldset>
                    <legend>From Email Address</legend>
                    <InPlaceText
                      id={this.site.id}
                      defaultValue={this.site.fromEmailAddress}
                      model="sites"
                      field="from_email_address"
                    />
                  </fieldset>
                </div>
                <div className="col-xs-4">
                  <fieldset>
                    <legend>BCC Email Addresses</legend>
                    <InPlaceText
                      id={this.site.id}
                      defaultValue={this.site.bccEmailAddresses}
                      model="sites"
                      field="bcc_email_addresses"
                    />
                    <div className='small'>Separate each item with a comma: a,b,c</div>
                  </fieldset>
                </div>
                <div className="col-xs-4">
                  <fieldset>
                    <legend>Enable MailerLite</legend>
                    <InPlaceCheckbox
                      id={this.site.id}
                      value={this.site.enableMailerlite}
                      model="sites"
                      field="enable_mailerlite"
                    />
                  </fieldset>
                </div>
                <div className="col-xs-12">
                  <fieldset>
                    <legend>Email Banner (w:1600px, h:226px)</legend>
                    <InPlaceImageUpload
                      image={this.site.emailBanner && this.site.emailBanner.image}
                      model="email_banners"
                      id={this.site.emailBanner?.id}
                      onChange={this.getSite}
                      querystring={{
                        site_id: this.site.id
                      }}
                    />
                  </fieldset>
                </div>
              </div>
              <Title>Posts</Title>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>PDF Banner Image (1160x230)</legend>
                    <InPlaceImageUpload
                      model="images"
                      image={GroupStore.showData.image}
                      onChange={() => GroupStore.reloadShow()}
                      querystring={{
                        imageable_id: GroupStore.showData.id,
                        imageable_type: 'Group'
                      }}
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Info Title</legend>
                    <InPlaceText
                      defaultValue={GroupStore.showData.infoTitle}
                      model="groups"
                      field="info_title"
                      id={GroupStore.showData.id}
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Info</legend>
                    <InPlaceText
                      richText
                      defaultValue={GroupStore.showData.info}
                      model="groups"
                      field="info"
                      id={GroupStore.showData.id}
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Info 2 Title</legend>
                    <InPlaceText
                      defaultValue={GroupStore.showData.info2Title}
                      model="groups"
                      field="info2_title"
                      id={GroupStore.showData.id}
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Info 2</legend>
                    <InPlaceText
                      richText
                      defaultValue={GroupStore.showData.info2}
                      model="groups"
                      field="info2"
                      id={GroupStore.showData.id}
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Vocabulary Translation</legend>
                    <InPlaceText
                      defaultValue={GroupStore.showData.vocabularyTranslation}
                      model="groups"
                      field="vocabulary_translation"
                      id={GroupStore.showData.id}
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Exercises Translation</legend>
                    <InPlaceText
                      defaultValue={GroupStore.showData.exercisesTranslation}
                      model="groups"
                      field="exercises_translation"
                      id={GroupStore.showData.id}
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Solutions Translation</legend>
                    <InPlaceText
                      defaultValue={GroupStore.showData.solutionsTranslation}
                      model="groups"
                      field="solutions_translation"
                      id={GroupStore.showData.id}
                    />
                  </fieldset>
                </div>
              </div>
              <Title>Post Vocab Settings</Title>
              <div className='row'>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Vocab settings</legend>
                      <div>
                        <InPlaceCheckbox
                          id={this.site.id}
                          value={this.site.showWordPages}
                          model="sites"
                          field="show_word_pages"
                          icon="check"
                          disabled={!this.site.language?.hasLessons}
                        >
                          Link to Word Pages (with Video)
                          {
                            !this.site.language?.hasLessons &&
                              <InfoTooltip>This is disabled for languages currently without videos.</InfoTooltip>
                          }
                        </InPlaceCheckbox>
                      </div>
                      <div>
                        <InPlaceCheckbox
                          id={this.site.id}
                          value={this.site.showWordStress}
                          model="sites"
                          field="show_word_stress"
                          icon="check"
                        >
                          Word Stress <InfoTooltip><div>By toggling this, you will have the ability</div><div>to underline characters in words to indicate stress</div></InfoTooltip>
                        </InPlaceCheckbox>
                      </div>
                      <div>
                        <InPlaceCheckbox
                          id={this.site.id}
                          value={this.site.showPlural}
                          model="sites"
                          field="show_plural"
                          icon="check"
                        >
                          Show Plural
                        </InPlaceCheckbox>
                      </div>
                      <div>
                        <InPlaceCheckbox
                          model="sites"
                          field="show_alternate_target_text"
                          value={this.site.showAlternateTargetText}
                          id={this.site.id}
                          icon="check"
                          afterChange={this.getSite}
                        >
                          Show Romanized spelling <InfoTooltip>Useful for languages that use several writing systems.</InfoTooltip>
                        </InPlaceCheckbox>
                        <div>
                      </div>
                        <InPlaceCheckbox
                          value={this.site.hideDefiniteArticle}
                          model="sites"
                          icon="check"
                          field="hide_definite_article"
                          id={this.site.id}
                          afterChange={this.getSite}
                        >
                          Hide Articles & Show Gender
                          <InfoTooltip>
                            <div>By selecting this option, articles will no longer be displayed before nouns,</div>
                            <div>and gender abbreviations will be added after nouns.</div></InfoTooltip>
                        </InPlaceCheckbox>
                      </div>
                    {
                      this.site.showAlternateTargetText &&
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <Text
                            fontSize="12px"
                            wrap="nowrap"
                            margin="0 5px 0 0"
                          >
                            Column Name:
                          </Text>
                          <InPlaceText
                            submitOnEnter
                            id={this.site.id}
                            defaultValue={this.site.alternateTargetTextName}
                            model="sites"
                            field="alternate_target_text_name"
                            show={isBlank(this.site.alternateTargetTextName)}
                            afterChange={this.getSite}
                            fontSize="12px"
                          />
                          {
                            isBlank(this.site.alternateTargetTextName) &&
                              <i className='fa fa-warning' />
                          }
                        </div>
                    }
                      <InPlaceCheckbox
                        model="sites"
                        field="show_level_column"
                        value={SiteStore.showData.showLevelColumn}
                        id={SiteStore.showData.id}
                        icon="check"
                      >
                        Show level column
                      </InPlaceCheckbox>
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Filter settings</legend>
                      <InPlaceCheckbox
                        model="sites"
                        field="show_level_filter"
                        value={SiteStore.showData.showLevelFilter}
                        id={SiteStore.showData.id}
                        icon="check"
                      >
                        Show level filters
                      </InPlaceCheckbox>
                      <InPlaceCheckbox
                        model="sites"
                        field="show_word_type_filter"
                        value={SiteStore.showData.showWordTypeFilter}
                        id={SiteStore.showData.id}
                        icon="check"
                      >
                        Show word type filters
                      </InPlaceCheckbox>
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Sorting Settings</legend>
                      <InPlaceCheckbox
                        model="sites"
                        field="show_alphabetical_sort"
                        value={SiteStore.showData.showAlphabeticalSort}
                        id={SiteStore.showData.id}
                        icon="check"
                      >
                        Show alphabetical sort
                      </InPlaceCheckbox>
                      <InPlaceCheckbox
                        model="sites"
                        field="show_chronological_sort"
                        value={SiteStore.showData.showChronologicalSort}
                        id={SiteStore.showData.id}
                        icon="check"
                      >
                        Show chronological sort
                      </InPlaceCheckbox>
                      <InPlaceCheckbox
                        model="sites"
                        field="show_level_sort"
                        value={SiteStore.showData.showLevelSort}
                        id={SiteStore.showData.id}
                        icon="check"
                      >
                        Show level sort
                      </InPlaceCheckbox>
                  </fieldset>
                </div>
              </div>
              <Title>Post Exercise Settings</Title>
              <div className='row'>
                <div className='col-xs-6'>
                  <fieldset>
                    <legend>Capitalization</legend>
                    <Text fontSize="14px">If the user's input is <b>correct except for capitalization</b>, the answer is...</Text>
                      <ul>
                        <ListItem>
                          <RadioButton
                            margin="0 10px 0 0"
                            value={this.requireCorrectCase}
                            onClick={this.toggleRequireCorrectCase}
                          />
                          <Text fontSize="14px">incorrect</Text>
                        </ListItem>
                        <ListItem>
                          <RadioButton
                            margin="0 10px 0 0"
                            value={!this.requireCorrectCase}
                            onClick={this.toggleRequireCorrectCase}
                          />
                          <Text fontSize="14px">correct, but a warning is shown</Text>
                        </ListItem>
                      </ul>
                  </fieldset>
                </div>
                <div className='col-xs-6'>
                  <fieldset>
                    <legend>Special Characters</legend>
                    <Text fontSize="14px">If the user's input is <b>correct except for special characters</b> (accents, tilde, etc.), the answer is...</Text>
                      <ul>
                        <ListItem>
                          <RadioButton
                            margin="0 10px 0 0"
                            value={this.requireCorrectSpecialCharacters}
                            onClick={this.toggleRequireCorrectSpecialCharacters}
                          />
                          <Text fontSize="14px">incorrect</Text>
                        </ListItem>
                        <ListItem>
                          <RadioButton
                            margin="0 10px 0 0"
                            value={!this.requireCorrectSpecialCharacters}
                            onClick={this.toggleRequireCorrectSpecialCharacters}
                          />
                          <Text fontSize="14px">correct, but a warning is shown</Text>
                        </ListItem>
                      </ul>
                  </fieldset>
                </div>
              </div>
              <Title>User Settings</Title>
              <div className='row'>
                <div className='col-xs-6'>
                  <fieldset>
                    <legend>Audio feeds message</legend>
                    <Text fontSize="14px">
                      The message that appears at the top of the audio feeds page
                    </Text>
                    <InPlaceText
                      markdown
                      show
                      id={this.site.id}
                      inputType="textarea"
                      height="100px"
                      defaultValue={this.site.podcastSettingsMessage}
                      model="sites"
                      field="podcast_settings_message"
                      afterChange={this.getSite}
                      fontSize="12px"
                      style={{resize: 'auto'}}
                    />
                  </fieldset>
                </div>
              </div>
              <Title>Contact Section</Title>
              <div className='row'>
                <div className="col-xs-12">
                  <fieldset>
                    <legend>Default Support Email Address <InfoTooltip>Messages are sent to this address when users select no category or 'Other'.</InfoTooltip></legend>
                    <InPlaceText
                      id={this.site.id}
                      defaultValue={this.site.supportEmailAddress}
                      model="sites"
                      field="support_email_address"
                    />
                  </fieldset>
                </div>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Contact Categories</legend>
                    <Text fontSize="14px">
                    Users will be prompted to choose one of these contact categories, which will determine which address the message is sent to.
                    </Text>
                    <table className="table-wrapper">
                      <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th width="40px"/>
                      </tr>
                      </thead>
                      <tbody>
                        {
                          ContactCategoryStore.hasIndexData && ContactCategoryStore.indexData.map(item =>
                            <tr key={item.id}>
                              <td>
                                <InPlaceText
                                  id={item.id}
                                  defaultValue={item.name}
                                  model="contact_categories"
                                  field="name"
                                />
                              </td>
                              <td>
                                <InPlaceText
                                  id={item.id}
                                  defaultValue={item.email}
                                  model="contact_categories"
                                  field="email"
                                />
                              </td>
                              <td>
                                <DeleteButton
                                  onConfirm={() => this.deleteContactCategory(item.id)}
                                  message="Delete this contact category?"
                                  className="fa-block right"
                                />
                              </td>
                            </tr>
                          )
                        }
                        <tr
                          style={{cursor: 'pointer'}}
                        >
                          <td
                            colSpan="3"
                            onClick={this.createContactCategory}
                            className="underline"
                          >
                            <i className='fa fa-plus' style={{margin: '3px'}}/>
                            <Text fontSize="14px">Create a new contact category</Text>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>
                </div>
              </div>
            </>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SiteEdit);
