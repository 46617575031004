import React from 'react';
import { observer } from 'mobx-react';
import { AppUI, ExerciseUI, ForumUI } from '@seedlang/state';
import ExerciseInputOptions from 'components/exercise/exercise_input_options';
import ExerciseInputDrag from 'components/exercise/exercise_input_drag';
import ExerciseInputEnter from 'components/exercise/exercise_input_enter';
import ExerciseMatchText from 'components/exercise/exercise_match_text';
import ExerciseReorder from 'components/exercise/exercise_reorder';
import ExerciseMatchMedia from 'components/exercise/exercise_match_media';
import ExerciseHighlight from 'components/exercise/exercise_highlight';
import ExerciseSentenceEnter from 'components/exercise/exercise_sentence_enter';
import ExerciseMultipleChoiceMedia from "components/exercise/exercise_multiple_choice_media";
import ExerciseInformation from "components/exercise/exercise_information";
import { isPresent } from '@seedlang/utils';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Button from 'components/button/button';
import Text from 'components/text';
import ExpandableDescription from 'components/exercise/expandable_description';
import Description from 'components/exercise/description';
import { computed, observable } from "mobx";
import autobind from "autobind-decorator";
import { Link } from "react-router";
import ExerciseNavigation from "components/worksheet/exercise_navigation";
import ReactTooltip from 'react-tooltip';
import isBlank from "is-blank";
import CommentIndex from "components/comment/comment_index";
import Spinner from "components/spinner";
import ExerciseButtons from 'components/worksheet/exercise_buttons';
import XpAnimation from 'components/xp_animation';
import NoAccessButton from "components/worksheet/no_access_button";

const COMPONENTS = {
  matchText: ExerciseMatchText,
  reorder: ExerciseReorder,
  inputEnter: ExerciseInputEnter,
  inputDrag: ExerciseInputDrag,
  inputOptions: ExerciseInputOptions,
  matchMedia: ExerciseMatchMedia,
  sentenceEnter: ExerciseSentenceEnter,
  highlight: ExerciseHighlight,
  multipleChoice: ExerciseMultipleChoiceMedia,
  information: ExerciseInformation
};

const Wrapper = styled.div`
  .fa-angle-left {
    font-weight: bold;
    margin-right: 5px;
    font-size: 20px;
    line-height: 25px;
  }
`;

const Content = styled.div`
  background: white;
  padding: 20px;
  border-radius: ${(props) => props.borderRadius}; 
`;

const Exercise = styled.div`

`;

const AdminLink = styled.div`
  margin-top: 5px;
  font-size: 11px;
  text-decoration: underline;
  text-align: center;
  padding: 5px;
`;

const DownloadLink = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  color: #353535;
  .fa {
    margin-left: 5px;
  }
`;

const InnerWrapper = styled.div`
  display: grid;
  grid-template-columns: 18% 80%;
  gap: 20px;
  @media (max-width: 899px) {
    display: flex;
    flex-direction: column;
  }
  height: ${props => props.hasAccess ? null : '250px'};
  -webkit-mask-image: ${props => props.hasAccess ? null : "linear-gradient(to bottom,black,70%,transparent)"};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 10px 0;
  align-items: center;
`;

const TagWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin: 5px 0;
  flex-wrap: wrap;
`;

const Tag = styled.div`
  color: #353535;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 12px;
  background: #efefef;
`;

@observer
class ExerciseShow extends React.Component {
  @observable showUserSettings;
  @observable comment = "";
  @observable showRatingComment = false;
  @observable ratingCommentSubmitted = false;
  @observable showSpinner = true;

  constructor(props) {
    super(props);
    ExerciseUI.set('exerciseId', this.props.params.exerciseId);
    ExerciseUI.enrichExercise(this.props.params.exerciseId, () => this.showSpinner = false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.params.exerciseId !== this.props.params.exerciseId) {
      ExerciseUI.set('exerciseId', this.props.params.exerciseId);
      ExerciseUI.enrichExercise(this.props.params.exerciseId, () => AppUI.scrollToPageTop('auto'));
      this.showRatingComment = false;
      this.ratingCommentSubmitted = false;
      this.comment = "";
    }
  }

  componentWillUnmount() {
    ExerciseUI.set('exerciseId', null);
  }

  @autobind topIsInView(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  @autobind setComment(comment) {
    this.comment = comment;
  }

  @autobind afterSubmitComment() {
    this.commentTimestamp = +new Date();
  }

  @autobind goBackToTop() {
    AppUI.scrollToPageTop();
  }

  @autobind onSubmitRating(rating) {
    this.showRatingComment = true;
    ExerciseUI.onClickRating(rating);
  }

  @computed get exercisesAreBlocked() {
    return !ExerciseUI.worksheet.userHasAccess || (AppUI.exercisesBlockedUntilMigrated && !ExerciseUI.worksheet.accessWithoutMembership);
  }

  @autobind onSubmitRatingComment() {
    ExerciseUI.onSubmitRatingComment(this.comment);
    this.ratingCommentSubmitted = true;
    this.comment = '';
  }

  @computed get contentBorderRadius() {
    if (ExerciseUI.layout.isMobile) {
      return "0";
    }
    if (ExerciseUI.exercise?.forumThread && ExerciseUI.site.showWorksheetComments) {
      if (this.props.tabCount <= 1) {
        return '10px 10px 0 0'
      }
      return '0px 10px 0 0';
    } if (this.props.tabCount <= 1) {
      return '10px';
    }
    return '0 10px 10px 10px';
  }

  @computed get commentsBorderRadius() {
    if (ExerciseUI.layout.isMobile) {
      return "0";
    }
    return "0 0 10px 10px";
  }

  @autobind goToNextExercise() {
    ExerciseUI.goToNextExercise(() => AppUI.scrollToPageTop('auto'), ExerciseUI.hasWorksheet ? `${ExerciseUI.worksheetNamespace}.exercises.show` : `exercises.show`);
  }

  render() {
    return (
      <Wrapper>
        <Content
          borderRadius={this.contentBorderRadius}
        >
          <InnerWrapper
            hasAccess={!this.exercisesAreBlocked}
          >
            <ExerciseNavigation
              padding="0"
              margin="0 0 20px 0"
              exercises={ExerciseUI.hasWorksheet && ExerciseUI.worksheetStore.showData.exercises.filter(item => item.exerciseType.slug !== null)}
              currentExerciseId={ExerciseUI.exercise?.id}
              userExercises={ExerciseUI.worksheetUserExerciseStore.indexData}
              worksheetId={ExerciseUI.worksheet?.id}
              groupId={ExerciseUI.worksheet?.groupId}
              useWorksheetRoute={ExerciseUI.hasWorksheet}
              afterRouteToExercise={this.scrollToTop}
              hasAccess={!this.exercisesAreBlocked}
            />
            {
              ExerciseUI.layout.isMobile && ExerciseUI.hasWorksheet && ExerciseUI.worksheet?.exercises.length > 0 &&
                <div style={{margin: '-20px 0', textAlign: 'right'}}>
                  <i
                    className='fa fa-gear'
                    onClick={this.props.onClickExerciseSettingsIcon}
                  />
                </div>
            }
          {
            ExerciseUI.hasExercise &&
              <Exercise>
                <TitleWrapper>
                  <Text heading={3}>{ExerciseUI.exercise.name}</Text>
                  {
                    <TagWrapper>
                      {
                        ExerciseUI.exercise && ExerciseUI.exercise.exerciseTags.map(item => {
                          return (
                            <Tag
                              key={item.id}
                              className='tag'
                            >
                              {item.tag.name}
                            </Tag>
                          )
                        })
                      }
                    </TagWrapper>
                  }
                </TitleWrapper>
                {
                  ExerciseUI.exercise.description && ExerciseUI.exercise.autoExpandDescription &&
                    <Description>
                      <div
                        dangerouslySetInnerHTML={{ __html: ExerciseUI.exercise.descriptionFormatted }}
                      />
                    </Description>
                }
                {
                  ExerciseUI.exercise.defaultDescription &&
                    <ExpandableDescription
                      exerciseId={ExerciseUI.exercise?.id}
                      defaultDescription={ExerciseUI.exercise.defaultDescriptionFormatted}
                      expandedDescription={ExerciseUI.exercise.expandedDescriptionFormatted}
                      descriptionVideo={ExerciseUI.exercise.formattedDescriptionVideo}
                      blockExpansion={this.exercisesAreBlocked}
                    />
                }
                {
                  !ExerciseUI.exercise?.enriched && ExerciseUI.exercise?.exerciseEntriesCount > 0 &&
                    <Spinner
                      background={AppUI.site.accentColor || '#868686'}
                  />
                }
                {
                  isPresent(ExerciseUI.exercise) && isPresent(ExerciseUI.exercise.exerciseType) && isPresent(ExerciseUI.exercise.exerciseType.slug) &&
                    React.createElement(COMPONENTS[ExerciseUI.exercise.exerciseType.slug],
                      {
                        exerciseId: ExerciseUI.exercise.id,
                        isMobile: ExerciseUI.layout.isMobile,
                        hideEnglishPrompts: ExerciseUI.user.hideEnglishPrompts,
                        inDeck: false,
                      }
                    )
                }
                {
                  !this.exercisesAreBlocked && isPresent(ExerciseUI.exercise) && ExerciseUI.exercise.exerciseEntriesCount > 0 && isPresent(ExerciseUI.exercise.exerciseType) && isPresent(ExerciseUI.exercise.exerciseType.slug) &&
                    <ExerciseButtons
                      exercisesAreBlocked={this.exercisesAreBlocked}
                      goToNextExercise={this.goToNextExercise}
                      showRatingComment={this.showRatingComment}
                      goBackToTop={this.goBackToTop}
                      onSubmitRating={this.onSubmitRating}
                      onSubmitRatingComment={this.onSubmitRatingComment}
                      setComment={this.setComment}
                      comment={this.comment}
                      ratingCommentSubmitted={this.ratingCommentSubmitted}
                    />
                }
                {
                  AppUI.siteIsDefault &&
                    <XpAnimation
                      namespace='user-exercise-show'
                    />
                }
              </Exercise>
          }
          </InnerWrapper>
          {
            ExerciseUI.hasWorksheet && this.exercisesAreBlocked &&
              <NoAccessButton
                migrate={AppUI.vocabBlockedUntilMigrated}
                register={ExerciseUI.worksheet.accessWithoutMembership}
              />
          }
        </Content>
        {
          ExerciseUI.exercise?.forumThread && ExerciseUI.site.showWorksheetComments &&
            <CommentIndex
              hideUserProfile
              showCommentCreate={!this.exercisesAreBlocked}
              commentTimestamp={this.commentTimestamp}
              groupId={ExerciseUI.exercise.worksheet.groupId}
              inModal={false}
              post={ExerciseUI.exercise.forumThread}
              showCommentCount
              user={ForumUI.user}
              commentable={ExerciseUI.exercise.forumThread}
              commentableType="ForumThread"
              forumThreadId={ExerciseUI.exercise.forumThread?.id}
              margin="0"
              languageId={ExerciseUI.siteIsDefault ? 'DE' : ExerciseUI.site.languageId}
              borderRadius={this.commentsBorderRadius}
              padding="40px 20px 20px 20px"
              placeholder={`Leave a comment about the exercise`}
              indentation={ExerciseUI.layout.isMobile ? 20 : 90}
            />
        }
        {
          ExerciseUI.user.anyAdmin && ExerciseUI.exercise && ExerciseUI.exercise.worksheet && ExerciseUI.exercise.worksheet.groupId  &&
            <AdminLink>
              <Link
                to={{name: 'creator.groups.exercises.edit', params: {groupId: ExerciseUI.exercise.worksheet.groupId, worksheetId: ExerciseUI.exercise.worksheet.id, exerciseId: ExerciseUI.exercise.id}}}
              >
                Admin Link
              </Link>
            </AdminLink>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ExerciseShow);
